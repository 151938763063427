import ImageHelper from "../../Helpers/ImageHelper";

const PortfolioDataWeb = [
  {
    title: "TEZ Tiger NFT",
    caption: "NFT Collectibles Website",
    image: ImageHelper.Web1,
    fullWebSS: true,
  },
  {
    title: "TZ Creation",
    caption: "Company Website",
    image: ImageHelper.Web2,
    fullWebSS: true,
  },
  {
    title: "The Zaika",
    caption: "Restaurant Website",
    image: ImageHelper.Web3,
    fullWebSS: true,
  },
  {
    title: "IndieSports",
    caption: "College Project",
    image: ImageHelper.Web4,
    fullWebSS: true,
  },
  {
    title: "Bidroid Auctoin App",
    caption: "App Landing Page",
    image: ImageHelper.Web5,
    fullWebSS: true,
  },
  {
    title: "OneGlint",
    caption: "Business Website",
    image: ImageHelper.Web6,
    fullWebSS: true,
  },
  {
    title: "EdXplore",
    caption: "Eduction Website",
    image: ImageHelper.Web7,
    fullWebSS: true,
  },
  {
    title: "Mobiotics",
    caption: "Entertainment Website",
    image: ImageHelper.Web8,
    fullWebSS: true,
  },
  {
    title: "GRM",
    caption: "Eduction Website",
    image: ImageHelper.Web9,
    fullWebSS: true,
  },
  // {
  //   title: "Pokemon Natural",
  //   caption: "Personal Project",
  //   image: ImageHelper.Web10,
  //   fullWebSS: false,
  // },
  // {
  //   title: "TEZ Crypto Tracker",
  //   caption: "Personal Project",
  //   image: ImageHelper.Web11,
  //   fullWebSS: false,
  // },
  // {
  //   title: "Pokemon Futuristic",
  //   caption: "Personal Project",
  //   image: ImageHelper.Web12,
  //   fullWebSS: false,
  // },
];

export default PortfolioDataWeb;
