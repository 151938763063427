import ImageHelper from "../../Helpers/ImageHelper";

const PortfolioDataLogo = [
  {
    title: "TZ Creation",
    caption: "Brand Identity",
    image: ImageHelper.Logo1,
  },
  {
    title: "Bidroid",
    caption: "Product Logo",
    image: ImageHelper.Logo2,
  },
  {
    title: "TZ Creation",
    caption: "Brand Identity",
    image: ImageHelper.Logo3,
  },
  {
    title: "RK Consultancy Logo",
    caption: "Brand Identity Logo",
    image: ImageHelper.Logo4,
  },
  {
    title: "IG TrendZ Logo",
    caption: "Instagram Page Logo",
    image: ImageHelper.Logo5,
  },
  {
    title: "Dal World",
    caption: "YouTube Channel Logo",
    image: ImageHelper.Logo6,
  },
  {
    title: "CricBuzz Logo",
    caption: "Brand Identity Logo",
    image: ImageHelper.Logo10,
  },
  {
    title: "Bihar Emergency Logo",
    caption: "Organisation Logo",
    image: ImageHelper.Logo11,
  },
  {
    title: "Fresh Express Dubai",
    caption: "Brand Identity Logo",
    image: ImageHelper.Logo21,
  },
  {
    title: "HackingSpot Logo",
    caption: "Tech Club Logo",
    image: ImageHelper.Logo7,
  },
  {
    title: "Penta Pass Logo",
    caption: "Brand Identity Logo",
    image: ImageHelper.Logo8,
  },
  {
    title: "iGhostia Affiliate Marketing",
    caption: "Brand Identity Logo",
    image: ImageHelper.Logo9,
  },
  {
    title: "Bihar Emergency Logo",
    caption: "Organisation Logo",
    image: ImageHelper.Logo11,
  },
  {
    title: "LB Logo",
    caption: "YouTube Channel Logo",
    image: ImageHelper.Logo16,
  },
  {
    title: "RedKen Hair Oil",
    caption: "Brand Identity Logo",
    image: ImageHelper.Logo17,
  },
  {
    title: "Deckoid Logo",
    caption: "Brand Identity Logo",
    image: ImageHelper.Logo18,
  },
  {
    title: "KevitChat Logo",
    caption: "Brand Identity Logo",
    image: ImageHelper.Logo19,
  },
  {
    title: "iGhostia Gaming Logo",
    caption: "Brand Identity Logo",
    image: ImageHelper.Logo20,
  },
  {
    title: "Madhav Logo",
    caption: "Brand Identity Logo",
    image: ImageHelper.Logo13,
  },
  {
    title: "Topclues Logo",
    caption: "Startup Logo",
    image: ImageHelper.Logo14,
  },
  {
    title: "Gaam No Choro Logo",
    caption: "Brand Identity Logo",
    image: ImageHelper.Logo12,
  },
];

export default PortfolioDataLogo;
