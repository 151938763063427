import ImageHelper from "../../Helpers/ImageHelper";

const PortfolioDataCatalogue = [
  {
    title: "Fashion Designer Catalogue",
    caption: "Portfolio Project",
    image: ImageHelper.DesignerPortfolio1,
  },
  {
    title: "Fashion Designer Catalogue",
    caption: "Portfolio Project",
    image: ImageHelper.DesignerPortfolio2,
  },
  {
    title: "Fashion Designer Catalogue",
    caption: "Portfolio Project",
    image: ImageHelper.DesignerPortfolio3,
  },
  {
    title: "Fashion Designer Catalogue",
    caption: "Event Management Website",
    image: ImageHelper.DesignerPortfolio4,
  },
  {
    title: "Fashion Designer Catalogue",
    caption: "Portfolio Project",
    image: ImageHelper.DesignerPortfolio5,
  },
  {
    title: "Fashion Designer Catalogue",
    caption: "Portfolio Project",
    image: ImageHelper.DesignerPortfolio6,
  },
  {
    title: "Denim Collection catalogue",
    caption: "Portfolio Project",
    image: ImageHelper.DenimPortfolio1,
  },
  {
    title: "Denim Collection catalogue",
    caption: "Event Management Website",
    image: ImageHelper.DenimPortfolio2,
  },
  {
    title: "Denim Collection catalogue",
    caption: "Portfolio Project",
    image: ImageHelper.DenimPortfolio3,
  },
  {
    title: "Denim Collection catalogue",
    caption: "Portfolio Project",
    image: ImageHelper.DenimPortfolio4,
  },
  {
    title: "Denim Collection catalogue",
    caption: "Portfolio Project",
    image: ImageHelper.DenimPortfolio5,
  },
  {
    title: "Denim Collection catalogue",
    caption: "Portfolio Project",
    image: ImageHelper.DenimPortfolio6,
  },
  // {
  //   title: "Denim Collection catalogue",
  //   caption: "Portfolio Project",
  //   image: ImageHelper.DenimPortfolio7,
  // },
];

export default PortfolioDataCatalogue;
