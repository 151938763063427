import ImageHelper from "../../Helpers/ImageHelper";

export const ToolsSectionData = [
  {
    id: 1,
    toolName: "Adobe Photoshop",
    toolLogo: ImageHelper.PhotoshopLogo,
  },
  {
    id: 2,
    toolName: "Adobe Illustrator",
    toolLogo: ImageHelper.IllustratorLogo,
  },
  {
    id: 3,
    toolName: "Adobe XD",
    toolLogo: ImageHelper.AdobeXdLogo,
  },
  {
    id: 4,
    toolName: "Figma",
    toolLogo: ImageHelper.FigmaLogo,
  },
  {
    id: 5,
    toolName: "VS Code",
    toolLogo: ImageHelper.VisualStudioLogo,
  },
  {
    id: 6,
    toolName: "Git Version Control",
    toolLogo: ImageHelper.GithubLogo,
  },
];
