const ConstantHelper = {
  CTACall: "tel:+1 (217) 339-9455",
  CTAChat:
    "https://api.whatsapp.com/send/?phone=+12173399455&text=*Hello+TZ+Creation+Team*,+I+have+some+queries,+regarding+project+/+service+/+pricing+such+as",

  RefundRequestWhatsppMessage:
    "https://api.whatsapp.com/send/?phone=+12173399455&text=*Hello+TZ+Creation+Team*,+I+want+to+raise+the+refund+request.",

  shareOnPinterestUrl: "https://www.pinterest.com/pin/create/button/?url=",
  shareOnTwitterUrl: "https://twitter.com/intent/tweet?url=",
  shareOnFacebookUrl: "https://www.facebook.com/sharer/sharer.php?u=",
  shareOnLinkedinUrl:
    "https://www.linkedin.com/shareArticle?mini=true&amp;url==",
  shareOnInstagramUrl: "https://www.instagram.com/tabrezdal/",
  copyLink: "https://www.instagram.com/tabrezdal/",

  TestimonialSectionTitle: "What my clients says",
  WriteReviewButton: "Write your review",
  goolgeReviewUrl: "https://g.page/r/CU2hmjyiPOGXEBM/review",
};

export default ConstantHelper;
