// Hero Section
import HeroImage from "../Assets/Images/me/TZ-at-old-house.webp";

// //About Section
import AboutImage from "../Assets/Images/me/Tabrez-at-dubai-mall.webp";
// import locationMap from "../Assets/Images/About/locationMap.webp";

import TzInSuit from "../Assets/Images/me/TZ-in-suit.webp";

// Tools Section
import PhotoshopLogo from "../Assets/Images/tools-icons/adobe-photoshop.svg";
import IllustratorLogo from "../Assets/Images/tools-icons/adobe-illustrator.svg";
import AdobeXdLogo from "../Assets/Images/tools-icons/adobe-xd.svg";
import FigmaLogo from "../Assets/Images/tools-icons/figma.svg";
import VisualStudioLogo from "../Assets/Images/tools-icons/visual-studio.svg";
import GithubLogo from "../Assets/Images/tools-icons/github.svg";

// Technologies Section
import ReactIcon from "../Assets/Images/technologies-icons/react-js-icon.svg";
import ReduxIcon from "../Assets/Images/technologies-icons/redux-icon.svg";
import GraphQLIcon from "../Assets/Images/technologies-icons/graphql-icon.svg";
import NodeJsIcon from "../Assets/Images/technologies-icons/nodejs-icon.svg";
import ChatGPTIcon from "../Assets/Images/technologies-icons/chatgpt-icon.svg";
import GoogleBardIcon from "../Assets/Images/technologies-icons/google-bard-icon.svg";
import HtmlIcon from "../Assets/Images/technologies-icons/html-icon.svg";
import CssIcon from "../Assets/Images/technologies-icons/css-icon.svg";
import SaasIcon from "../Assets/Images/technologies-icons/sass-icon.svg";
import LessIcon from "../Assets/Images/technologies-icons/less-icon.svg";
import MuiIcon from "../Assets/Images/technologies-icons/MUI.svg";
import BootstrapIcon from "../Assets/Images/technologies-icons/bootstrap-5-icon.svg";
import MySqlIcon from "../Assets/Images/technologies-icons/mysql-icon.svg";
import JsonIcon from "../Assets/Images/technologies-icons/json-icon.svg";
import JavaScriptIcon from "../Assets/Images/technologies-icons/javascript-icon.svg";
import TypescriptIcon from "../Assets/Images/technologies-icons/typescript-icon.svg";
import AxiosIcon from "../Assets/Images/technologies-icons/axios-icon.svg";
import JqueryIcon from "../Assets/Images/technologies-icons/jquery-icon.svg";

// // Brands Section
// import brand1 from "../Assets/Images/Brands/tzapp.webp";
// import brand2 from "../Assets/Images/Brands/tzseo.webp";
// import brand3 from "../Assets/Images/Brands/tzsmm.webp";
// import brand4 from "../Assets/Images/Brands/tzvfx.webp";
// import brand5 from "../Assets/Images/Brands/tzweb.webp";

//Portfolio Images

// Webistes Portfolio
import Web1 from "../Assets/Images/Portfolio/Web/TEZTigerNFT.webp";
import Web2 from "../Assets/Images/Portfolio/Web/TZ Creation.webp";
import Web3 from "../Assets/Images/Portfolio/Web/TheZaika.webp";
import Web4 from "../Assets/Images/Portfolio/Web/IndieSports.webp";
import Web5 from "../Assets/Images/Portfolio/Web/Bidroid Web.webp";
import Web6 from "../Assets/Images/Portfolio/Web/OneGlint.webp";
import Web7 from "../Assets/Images/Portfolio/Web/EdXplore Frontend.webp";
import Web8 from "../Assets/Images/Portfolio/Web/Mobiotics - Homepage.webp";
import Web9 from "../Assets/Images/Portfolio/Web/GRM - Homepage.webp";
import Web10 from "../Assets/Images/Portfolio/Web/pokemon-1.webp";
import Web11 from "../Assets/Images/Portfolio/Web/CryptoTracker.webp";
import Web12 from "../Assets/Images/Portfolio/Web/pokemon-2.webp";

// Logo Design Portfolio
import Logo1 from "../Assets/Images/Portfolio/Logo-Design/TZ Creation Logo 1.webp";
import Logo2 from "../Assets/Images/Portfolio/Logo-Design/Bidroid Logo.webp";
import Logo3 from "../Assets/Images/Portfolio/Logo-Design/TZ Creation Logo 2.webp";
import Logo4 from "../Assets/Images/Portfolio/Logo-Design/RK Consultancy Logo.webp";
import Logo5 from "../Assets/Images/Portfolio/Logo-Design/IG TrendZ Logo.webp";
import Logo6 from "../Assets/Images/Portfolio/Logo-Design/Dal World Logo.webp";
import Logo7 from "../Assets/Images/Portfolio/Logo-Design/HackingSpot Logo.webp";
import Logo8 from "../Assets/Images/Portfolio/Logo-Design/Penta Pass Logo.webp";
import Logo9 from "../Assets/Images/Portfolio/Logo-Design/iGhostia.com Logo.webp";
import Logo10 from "../Assets/Images/Portfolio/Logo-Design/CricBuzz Logo.webp";
import Logo11 from "../Assets/Images/Portfolio/Logo-Design/Dial_112_in_Bihar_Logo.webp";
import Logo12 from "../Assets/Images/Portfolio/Logo-Design/Gaam No Choro Logo.webp";
import Logo13 from "../Assets/Images/Portfolio/Logo-Design/Madhav Logo.webp";
import Logo14 from "../Assets/Images/Portfolio/Logo-Design/Topclues Logo.webp";
import Logo16 from "../Assets/Images/Portfolio/Logo-Design/LB Logo.webp";
import Logo17 from "../Assets/Images/Portfolio/Logo-Design/redken-logo.webp";
import Logo18 from "../Assets/Images/Portfolio/Logo-Design/Deckoid Logo.webp";
import Logo19 from "../Assets/Images/Portfolio/Logo-Design/KevitChat Logo.webp";
import Logo20 from "../Assets/Images/Portfolio/Logo-Design/IGhostia Logo.webp";
import Logo21 from "../Assets/Images/Portfolio/Logo-Design/Fresh Express Logo.webp";

// Catalogue
import DenimPortfolio1 from "../Assets/Images/Portfolio/Catalogues/Denim Portfolio1.webp";
import DenimPortfolio2 from "../Assets/Images/Portfolio/Catalogues/Denim Portfolio2.webp";
import DenimPortfolio3 from "../Assets/Images/Portfolio/Catalogues/Denim Portfolio3.webp";
import DenimPortfolio4 from "../Assets/Images/Portfolio/Catalogues/Denim Portfolio4.webp";
import DenimPortfolio5 from "../Assets/Images/Portfolio/Catalogues/Denim Portfolio5.webp";
import DenimPortfolio6 from "../Assets/Images/Portfolio/Catalogues/Denim Portfolio6.webp";
import DenimPortfolio7 from "../Assets/Images/Portfolio/Catalogues/Denim Portfolio7.webp";
import DesignerPortfolio1 from "../Assets/Images/Portfolio/Catalogues/Designer Portfolio1.webp";
import DesignerPortfolio2 from "../Assets/Images/Portfolio/Catalogues/Designer Portfolio2.webp";
import DesignerPortfolio3 from "../Assets/Images/Portfolio/Catalogues/Designer Portfolio3.webp";
import DesignerPortfolio4 from "../Assets/Images/Portfolio/Catalogues/Designer Portfolio4.webp";
import DesignerPortfolio5 from "../Assets/Images/Portfolio/Catalogues/Designer Portfolio5.webp";
import DesignerPortfolio6 from "../Assets/Images/Portfolio/Catalogues/Designer Portfolio6.webp";

// Social Meida Posts
import SMP1 from "../Assets/Images/Portfolio/Graphic/SMP1.webp";
import SMP2 from "../Assets/Images/Portfolio/Graphic/SMP2.webp";
import SMP3 from "../Assets/Images/Portfolio/Graphic/SMP3.webp";
import SMP4 from "../Assets/Images/Portfolio/Graphic/SMP4.webp";
import SMP5 from "../Assets/Images/Portfolio/Graphic/SMP5.webp";
import SMP6 from "../Assets/Images/Portfolio/Graphic/SMP6.webp";
import SMP7 from "../Assets/Images/Portfolio/Graphic/SMP7.webp";
import SMP8 from "../Assets/Images/Portfolio/Graphic/SMP8.webp";
import SMP9 from "../Assets/Images/Portfolio/Graphic/SMP9.webp";
import SMP10 from "../Assets/Images/Portfolio/Graphic/SMP10.webp";
import SMP11 from "../Assets/Images/Portfolio/Graphic/SMP11.webp";
import SMP12 from "../Assets/Images/Portfolio/Graphic/SMP12.webp";
import SMP13 from "../Assets/Images/Portfolio/Graphic/SMP13.webp";
import SMP14 from "../Assets/Images/Portfolio/Graphic/SMP14.webp";
import SMP15 from "../Assets/Images/Portfolio/Graphic/SMP15.webp";
import SMP16 from "../Assets/Images/Portfolio/Graphic/SMP16.webp";
import SMP17 from "../Assets/Images/Portfolio/Graphic/SMP17.webp";
import SMP18 from "../Assets/Images/Portfolio/Graphic/SMP18.webp";
import SMP19 from "../Assets/Images/Portfolio/Graphic/SMP19.webp";
import SMP20 from "../Assets/Images/Portfolio/Graphic/SMP20.webp";
import SMP21 from "../Assets/Images/Portfolio/Graphic/SMP21.webp";
import SMP22 from "../Assets/Images/Portfolio/Graphic/SMP22.webp";
import SMP23 from "../Assets/Images/Portfolio/Graphic/SMP23.webp";
import SMP24 from "../Assets/Images/Portfolio/Graphic/SMP24.webp";
import SMP25 from "../Assets/Images/Portfolio/Graphic/SMP25.webp";
import SMP26 from "../Assets/Images/Portfolio/Graphic/SMP26.webp";
import SMP27 from "../Assets/Images/Portfolio/Graphic/SMP27.webp";
import SMP28 from "../Assets/Images/Portfolio/Graphic/SMP28.webp";
import SMP29 from "../Assets/Images/Portfolio/Graphic/SMP29.webp";
import SMP30 from "../Assets/Images/Portfolio/Graphic/SMP30.webp";
import SMP31 from "../Assets/Images/Portfolio/Graphic/SMP31.webp";
import SMP32 from "../Assets/Images/Portfolio/Graphic/SMP32.webp";
import SMP33 from "../Assets/Images/Portfolio/Graphic/SMP33.webp";
import SMP34 from "../Assets/Images/Portfolio/Graphic/SMP34.webp";
import SMP35 from "../Assets/Images/Portfolio/Graphic/SMP35.webp";
import SMP36 from "../Assets/Images/Portfolio/Graphic/SMP36.webp";
import SMP37 from "../Assets/Images/Portfolio/Graphic/SMP37.webp";
import SMP38 from "../Assets/Images/Portfolio/Graphic/SMP38.webp";
import SMP39 from "../Assets/Images/Portfolio/Graphic/SMP39.webp";
import SMP40 from "../Assets/Images/Portfolio/Graphic/SMP40.webp";
import SMP41 from "../Assets/Images/Portfolio/Graphic/SMP41.webp";
import SMP42 from "../Assets/Images/Portfolio/Graphic/SMP42.webp";
import SMP43 from "../Assets/Images/Portfolio/Graphic/SMP43.webp";
import SMP44 from "../Assets/Images/Portfolio/Graphic/SMP44.webp";
import SMP45 from "../Assets/Images/Portfolio/Graphic/SMP45.webp";
import SMP46 from "../Assets/Images/Portfolio/Graphic/SMP46.webp";
import SMP47 from "../Assets/Images/Portfolio/Graphic/SMP47.webp";
import SMP48 from "../Assets/Images/Portfolio/Graphic/SMP48.webp";
import SMP49 from "../Assets/Images/Portfolio/Graphic/SMP49.webp";
import SMP50 from "../Assets/Images/Portfolio/Graphic/SMP50.webp";
import SMP51 from "../Assets/Images/Portfolio/Graphic/SMP51.webp";
import SMP52 from "../Assets/Images/Portfolio/Graphic/SMP52.webp";
import SMP53 from "../Assets/Images/Portfolio/Graphic/SMP53.webp";
import SMP54 from "../Assets/Images/Portfolio/Graphic/SMP54.webp";
import SMP55 from "../Assets/Images/Portfolio/Graphic/SMP55.webp";
import SMP56 from "../Assets/Images/Portfolio/Graphic/SMP56.webp";
import SMP57 from "../Assets/Images/Portfolio/Graphic/SMP57.webp";
import SMP58 from "../Assets/Images/Portfolio/Graphic/SMP58.webp";
import SMP59 from "../Assets/Images/Portfolio/Graphic/SMP59.webp";
import SMP60 from "../Assets/Images/Portfolio/Graphic/SMP60.webp";
import SMP61 from "../Assets/Images/Portfolio/Graphic/SMP61.webp";
import SMP62 from "../Assets/Images/Portfolio/Graphic/SMP62.webp";
import SMP63 from "../Assets/Images/Portfolio/Graphic/SMP63.webp";
import SMP64 from "../Assets/Images/Portfolio/Graphic/SMP64.webp";
import SMP65 from "../Assets/Images/Portfolio/Graphic/SMP65.webp";
import SMP66 from "../Assets/Images/Portfolio/Graphic/SMP66.webp";
import SMP67 from "../Assets/Images/Portfolio/Graphic/SMP67.webp";
import SMP68 from "../Assets/Images/Portfolio/Graphic/SMP68.webp";
import SMP69 from "../Assets/Images/Portfolio/Graphic/SMP69.webp";
import SMP70 from "../Assets/Images/Portfolio/Graphic/SMP70.webp";
import SMP71 from "../Assets/Images/Portfolio/Graphic/SMP71.webp";
import SMP72 from "../Assets/Images/Portfolio/Graphic/SMP72.webp";
import SMP73 from "../Assets/Images/Portfolio/Graphic/SMP73.webp";
import SMP74 from "../Assets/Images/Portfolio/Graphic/SMP74.webp";
import SMP75 from "../Assets/Images/Portfolio/Graphic/SMP75.webp";
import SMP76 from "../Assets/Images/Portfolio/Graphic/SMP76.webp";
import SMP77 from "../Assets/Images/Portfolio/Graphic/SMP77.webp";
import SMP78 from "../Assets/Images/Portfolio/Graphic/SMP78.webp";
import SMP79 from "../Assets/Images/Portfolio/Graphic/SMP79.webp";
import SMP80 from "../Assets/Images/Portfolio/Graphic/SMP80.webp";
import SMP81 from "../Assets/Images/Portfolio/Graphic/SMP81.webp";
import SMP82 from "../Assets/Images/Portfolio/Graphic/SMP82.webp";
import SMP83 from "../Assets/Images/Portfolio/Graphic/SMP83.webp";
import SMP84 from "../Assets/Images/Portfolio/Graphic/SMP84.webp";
import SMPFull1 from "../Assets/Images/Portfolio/Graphic/Eid_Al_Adha_Full.webp";
import SMPFull2 from "../Assets/Images/Portfolio/Graphic/Muharram_Full.webp";
import SMPFull3 from "../Assets/Images/Portfolio/Graphic/We_Are_Hiring_Full.webp";

// Ui/Ux
import BidroidNewUI from "../Assets/Images/Portfolio/UiUx/Bidroid App New UI.webp";
import BidroidOldUI from "../Assets/Images/Portfolio/UiUx/Bidroid App Old UI.webp";
import CricBuzzUI from "../Assets/Images/Portfolio/UiUx/CricBuzz App UI.webp";
import SoyHill1 from "../Assets/Images/Portfolio/UiUx/SoyHill-1.webp";
import SoyHill2 from "../Assets/Images/Portfolio/UiUx/SoyHill-2.webp";
import SoyHill3 from "../Assets/Images/Portfolio/UiUx/SoyHill-3.webp";
import BidroidAppBanner from "../Assets/Images/Portfolio/UiUx/Bidroid-App-Banner.webp";
import FinverseGuruRevampedbyTZ from "../Assets/Images/Portfolio/UiUx/Finverse-Guru-Revamped-by-TZ-Cover.webp";
import HopRegistrationFlowDesign from "../Assets/Images/Portfolio/UiUx/Hop-Registration-Flow-Design-Cover.webp";
import KidzyHabitTrackerAppUiConceptsbyTZ from "../Assets/Images/Portfolio/UiUx/Kidzy Habit Tracker App Ui Concepts by TZ [Cover].webp";
import KingGraphicsTask4 from "../Assets/Images/Portfolio/UiUx/King Graphics Task - 4 [Cover].webp";
import LevelNextWeb from "../Assets/Images/Portfolio/UiUx/LevelNext Web [Cover].webp";
import LinkedupAppUiDesign from "../Assets/Images/Portfolio/UiUx/Linkedup App Ui Design [Cover].webp";
import MenuOnline from "../Assets/Images/Portfolio/UiUx/MenuOnline [Cover].webp";
import MenuOnlineFoundationCover from "../Assets/Images/Portfolio/UiUx/MenuOnline-Foundation-Cover.webp";
import MyHabitAppUiDesignCover from "../Assets/Images/Portfolio/UiUx/MyHabit-App-Ui-Design-Cover.webp";
import PropertyZoneLandingPageCover from "../Assets/Images/Portfolio/UiUx/PropertyZone-Landing-Page-Cover.webp";
import RushInvoiceAppCover from "../Assets/Images/Portfolio/UiUx/Rush-Invoice-App-Cover.webp";
import SOSEmergencyAppCover from "../Assets/Images/Portfolio/UiUx/SOS-Emergency-App-Cover.webp";
import TabrezDalExpertProductDesignerCover from "../Assets/Images/Portfolio/UiUx/Tabrez-Dal-Expert-Product-Designer-Cover.webp";
import TezHUBCover from "../Assets/Images/Portfolio/UiUx/TezHUB-Cover.webp";
import EatExpress from "../Assets/Images/Portfolio/UiUx/EatExpress.webp";
import CasualChic from "../Assets/Images/Portfolio/UiUx/CasualChic.webp";
import A1VIsasPassportServicesLandingPage from "../Assets/Images/Portfolio/UiUx/A1-Visas-Passport-Photo-Service-Landing-Page-Cover.webp";
import Artyss from "../Assets/Images/Portfolio/UiUx/Artyss Cover.webp";
import EdXploreAdminPanelMobileVersion from "../Assets/Images/Portfolio/UiUx/EdXplore-Admin-Panel-_Mobile-Version-Cover.webp";
import EdXploreAdminPanel from "../Assets/Images/Portfolio/UiUx/EdXplore-Admin-Panel-Cover.webp";
import EdXploreHeaderRevamp from "../Assets/Images/Portfolio/UiUx/EdXplore-Header-Revamp-Cover.webp";
import FamilyOffice360 from "../Assets/Images/Portfolio/UiUx/FamilyOffice360-Cover.webp";
import FMSCIHomepageRevamp from "../Assets/Images/Portfolio/UiUx/FMSCI-Homepage-Revamp-Cover.webp";
import GRM from "../Assets/Images/Portfolio/UiUx/GRM-Cover.webp";
import HotelResortManagement from "../Assets/Images/Portfolio/UiUx/Hotel-Resort-Management-Cover.webp";
import SaishHF from "../Assets/Images/Portfolio/UiUx/Saish-H-&-F-Cover.webp";
import SkillMineIndia from "../Assets/Images/Portfolio/UiUx/SkillMine [India]-Cover.webp";
import SkillMineKsa from "../Assets/Images/Portfolio/UiUx/SkillMine [KSA]-Cover.webp";
import SkillMineUk from "../Assets/Images/Portfolio/UiUx/SkillMine [UK]-Cover.webp";
import SkillMineAuth from "../Assets/Images/Portfolio/UiUx/SkillMine Auth-Cover.webp";
import SundeshSolarWebsiteRevamp from "../Assets/Images/Portfolio/UiUx/Sundesh Solar Website Revamp-Cover.webp";
import TeachersIndiaUiRevamped from "../Assets/Images/Portfolio/UiUx/TeachersIndia UI Revamped-Cover.webp";
import UpStreamLife from "../Assets/Images/Portfolio/UiUx/UpStreamLife-Cover.webp";
import ZeroZillaProfile from "../Assets/Images/Portfolio/UiUx/ZeroZilla Profile-Cover.webp";
import MecoWebUi from "../Assets/Images/Portfolio/UiUx/Meco-Cover.webp";
import MecoFinalWebUi from "../Assets/Images/Portfolio/UiUx/Meco-Final-Cover.webp";

// Poster
import CricBuzzBanner from "../Assets/Images/Portfolio/Poster/CricBuzz Banner.webp";
import FDVsRealEstate from "../Assets/Images/Portfolio/Poster/FD Vs Real Estate.webp";
import FreshExpressCatalog from "../Assets/Images/Portfolio/Poster/FreshExpress Catalog.webp";
import FreshExpressRecipeBook1 from "../Assets/Images/Portfolio/Poster/FreshExpress Recipe Book Dual Pages.webp";
import FreshExpressRecipeBook2 from "../Assets/Images/Portfolio/Poster/FreshExpress Recipe Book Pages.webp";
import IGhostiaPoster from "../Assets/Images/Portfolio/Poster/IGhostia Poster.webp";
import LinkedinCover from "../Assets/Images/Portfolio/Poster/Linkedin Cover.webp";
import MindBrowserInfographic from "../Assets/Images/Portfolio/Poster/MindBrowserInfographic.webp";
import NaazClassesPoster from "../Assets/Images/Portfolio/Poster/Naaz Classes Poster.webp";
import NobleLanManiaPoster from "../Assets/Images/Portfolio/Poster/Noble Lan Mania Poster.webp";
import TopcluesCTRPost from "../Assets/Images/Portfolio/Poster/Topclues CTR Post.webp";
import TopcluesInfographic from "../Assets/Images/Portfolio/Poster/Topclues Infographic.webp";

// Other
import Other1 from "../Assets/Images/Portfolio/Other/Book - CricBuzz.webp";
import Other2 from "../Assets/Images/Portfolio/Other/Book - FreshExpress.webp";
import Other3 from "../Assets/Images/Portfolio/Other/Box - TZ Creation.webp";
import Other4 from "../Assets/Images/Portfolio/Other/redken1.webp";
import Other5 from "../Assets/Images/Portfolio/Other/redken2.webp";
import Other6 from "../Assets/Images/Portfolio/Other/Topclues-Social-Media-Marketing.webp";
import Other7 from "../Assets/Images/Portfolio/Other/TZ Box Pack 1.webp";
import Other8 from "../Assets/Images/Portfolio/Other/TZ Box Pack 2.webp";
import Other9 from "../Assets/Images/Portfolio/Other/TZ Box Pack 3.webp";
import Other10 from "../Assets/Images/Portfolio/Other/TZ Certi 1.webp";
import Other11 from "../Assets/Images/Portfolio/Other/TZ Certi 2.webp";
import Other12 from "../Assets/Images/Portfolio/Other/TZ Vouchers.webp";

// import AirHmeInnerWork from "../Assets/Images/CaseStudies/airHmeInnerWork.webp";
// import BalleyInnerWork from "../Assets/Images/CaseStudies/balleyInnerWork.webp";
// import ColorJumper from "../Assets/Images/CaseStudies/color-jumper.webp";
// import DragonForceNinja from "../Assets/Images/CaseStudies/dragon-force-ninja.webp";
// import RideoInnerWork from "../Assets/Images/CaseStudies/RideoInnerWork.webp";
// import StackerCracker from "../Assets/Images/CaseStudies/stacker-cracker.webp";
// import TamkeenCare from "../Assets/Images/CaseStudies/tamkeen-care.webp";
// import WishInnerWork from "../Assets/Images/CaseStudies/wishInnerWork.webp";

// // Service Portfolio Banner Images

// import Branding from "../Assets/Images/ServicePortfolio/banner/branding-img.webp";
// import CustomWebDesign from "../Assets/Images/ServicePortfolio/banner/Custom-Web-Design-Services.webp";
// import DomainHosting from "../Assets/Images/ServicePortfolio/banner/domain-hosting-img.webp";
// import EcommerceDesign1 from "../Assets/Images/ServicePortfolio/banner/ecommerce-design1.webp";
// import EcommerceDesign2 from "../Assets/Images/ServicePortfolio/banner/ecommerce-design2.webp";
// import EcommerceDesign3 from "../Assets/Images/ServicePortfolio/banner/ecommerce-design3.webp";
// import Ecommerce from "../Assets/Images/ServicePortfolio/banner/e-commerce-img.webp";
// import LogoDesigning from "../Assets/Images/ServicePortfolio/banner/logo-designing-img.webp";
// import MobileAppDevelopment from "../Assets/Images/ServicePortfolio/banner/mobile-app-development-img.webp";
// import PsdToHtml from "../Assets/Images/ServicePortfolio/banner/psd-to-html.webp";
// import SEO from "../Assets/Images/ServicePortfolio/banner/seo-img.webp";
// import SSL from "../Assets/Images/ServicePortfolio/banner/ssl.webp";
// import videoAnimation from "../Assets/Images/ServicePortfolio/banner/video-animation-img.webp";
// import WebAppDevelopment from "../Assets/Images/ServicePortfolio/banner/web-app-development-img.webp";
// import webDesign from "../Assets/Images/ServicePortfolio/banner/web-design-img.webp";
// import websiteMaintenance from "../Assets/Images/ServicePortfolio/banner/website-maintenance-img.webp";
// import WeOffer from "../Assets/Images/ServicePortfolio/banner/we-offer-img.webp";

// import WebBanner1 from "../Assets/Images/ServiceSlider/Web/webBanner1.webp";
// import WebBanner2 from "../Assets/Images/ServiceSlider/Web/webBanner2.webp";
// import WebBanner3 from "../Assets/Images/ServiceSlider/Web/webBanner3.webp";
// import WebBanner4 from "../Assets/Images/ServiceSlider/Web/webBanner4.webp";

// import ECommerce1 from "../Assets/Images/ServiceSlider/ECommerce/ECommerce1.webp";
// import ECommerce2 from "../Assets/Images/ServiceSlider/ECommerce/ECommerce2.webp";
// import ECommerce3 from "../Assets/Images/ServiceSlider/ECommerce/ECommerce3.webp";
// import ECommerce4 from "../Assets/Images/ServiceSlider/ECommerce/ECommerce4.webp";
// import ECommerce5 from "../Assets/Images/ServiceSlider/ECommerce/ECommerce5.webp";
// import ECommerce6 from "../Assets/Images/ServiceSlider/ECommerce/ECommerce6.webp";

// import AppBanner1 from "../Assets/Images/ServiceSlider/App/appBanner1.webp";
// import AppBanner2 from "../Assets/Images/ServiceSlider/App/appBanner2.webp";
// import AppBanner3 from "../Assets/Images/ServiceSlider/App/appBanner3.webp";
// import AppBanner4 from "../Assets/Images/ServiceSlider/App/appBanner4.webp";
// import AppBanner5 from "../Assets/Images/ServiceSlider/App/appBanner5.webp";

const ImageHelper = {
  // Hero Section
  HeroImage,

  // //About Section
  AboutImage,
  // locationMap,
  TzInSuit,

  PhotoshopLogo,
  IllustratorLogo,
  AdobeXdLogo,
  FigmaLogo,
  VisualStudioLogo,
  GithubLogo,

  ReactIcon,
  ReduxIcon,
  GraphQLIcon,
  NodeJsIcon,
  ChatGPTIcon,
  GoogleBardIcon,
  HtmlIcon,
  CssIcon,
  SaasIcon,
  LessIcon,
  MuiIcon,
  BootstrapIcon,
  MySqlIcon,
  JsonIcon,
  JavaScriptIcon,
  TypescriptIcon,
  AxiosIcon,
  JqueryIcon,

  // // Brands Section
  // brand1,
  // brand2,
  // brand3,
  // brand4,
  // brand5,

  //Portfolio Images
  //Website Portfolio
  Web1,
  Web2,
  Web3,
  Web4,
  Web5,
  Web6,
  Web7,
  Web8,
  Web9,
  Web10,
  Web11,
  Web12,

  //Logo Portfolio
  Logo1,
  Logo2,
  Logo3,
  Logo4,
  Logo5,
  Logo6,
  Logo7,
  Logo8,
  Logo9,
  Logo10,
  Logo11,
  Logo12,
  Logo13,
  Logo14,
  Logo16,
  Logo17,
  Logo18,
  Logo19,
  Logo20,
  Logo21,

  //Catalogue
  DenimPortfolio1,
  DenimPortfolio2,
  DenimPortfolio3,
  DenimPortfolio4,
  DenimPortfolio5,
  DenimPortfolio6,
  DenimPortfolio7,
  DesignerPortfolio1,
  DesignerPortfolio2,
  DesignerPortfolio3,
  DesignerPortfolio4,
  DesignerPortfolio5,
  DesignerPortfolio6,

  // Social Media Posts
  SMP1,
  SMP2,
  SMP3,
  SMP4,
  SMP5,
  SMP6,
  SMP7,
  SMP8,
  SMP9,
  SMP10,
  SMP11,
  SMP12,
  SMP13,
  SMP14,
  SMP15,
  SMP16,
  SMP17,
  SMP18,
  SMP19,
  SMP20,
  SMP21,
  SMP22,
  SMP23,
  SMP24,
  SMP25,
  SMP26,
  SMP27,
  SMP28,
  SMP29,
  SMP30,
  SMP31,
  SMP32,
  SMP33,
  SMP34,
  SMP35,
  SMP36,
  SMP37,
  SMP38,
  SMP39,
  SMP40,
  SMP41,
  SMP42,
  SMP43,
  SMP44,
  SMP45,
  SMP46,
  SMP47,
  SMP48,
  SMP49,
  SMP50,
  SMP51,
  SMP52,
  SMP53,
  SMP54,
  SMP55,
  SMP56,
  SMP57,
  SMP58,
  SMP59,
  SMP60,
  SMP61,
  SMP62,
  SMP63,
  SMP64,
  SMP65,
  SMP66,
  SMP67,
  SMP68,
  SMP69,
  SMP70,
  SMP71,
  SMP72,
  SMP73,
  SMP74,
  SMP75,
  SMP76,
  SMP77,
  SMP78,
  SMP79,
  SMP80,
  SMP81,
  SMP82,
  SMP83,
  SMP84,
  SMPFull1,
  SMPFull2,
  SMPFull3,

  // Ui/Ux
  BidroidNewUI,
  BidroidOldUI,
  CricBuzzUI,
  SoyHill1,
  SoyHill2,
  SoyHill3,
  BidroidAppBanner,
  FinverseGuruRevampedbyTZ,
  HopRegistrationFlowDesign,
  KidzyHabitTrackerAppUiConceptsbyTZ,
  KingGraphicsTask4,
  LevelNextWeb,
  LinkedupAppUiDesign,
  MenuOnline,
  MenuOnlineFoundationCover,
  MyHabitAppUiDesignCover,
  PropertyZoneLandingPageCover,
  RushInvoiceAppCover,
  SOSEmergencyAppCover,
  TabrezDalExpertProductDesignerCover,
  TezHUBCover,
  EatExpress,
  CasualChic,
  A1VIsasPassportServicesLandingPage,
  Artyss,
  EdXploreAdminPanelMobileVersion,
  EdXploreAdminPanel,
  EdXploreHeaderRevamp,
  FamilyOffice360,
  FMSCIHomepageRevamp,
  GRM,
  HotelResortManagement,
  SaishHF,
  SkillMineIndia,
  SkillMineKsa,
  SkillMineUk,
  SkillMineAuth,
  SundeshSolarWebsiteRevamp,
  TeachersIndiaUiRevamped,
  UpStreamLife,
  ZeroZillaProfile,
  MecoWebUi,
  MecoFinalWebUi,

  // Poster
  CricBuzzBanner,
  FDVsRealEstate,
  FreshExpressCatalog,
  FreshExpressRecipeBook1,
  FreshExpressRecipeBook2,
  IGhostiaPoster,
  LinkedinCover,
  MindBrowserInfographic,
  NaazClassesPoster,
  NobleLanManiaPoster,
  TopcluesCTRPost,
  TopcluesInfographic,

  // Other
  Other1,
  Other2,
  Other3,
  Other4,
  Other5,
  Other6,
  Other7,
  Other8,
  Other9,
  Other10,
  Other11,
  Other12,

  // // Case Studies

  // AirHmeInnerWork,
  // BalleyInnerWork,
  // ColorJumper,
  // DragonForceNinja,
  // RideoInnerWork,
  // StackerCracker,
  // TamkeenCare,
  // WishInnerWork,

  // // Service Portfolio Banner Images
  // Branding,
  // CustomWebDesign,
  // DomainHosting,
  // EcommerceDesign1,
  // EcommerceDesign2,
  // EcommerceDesign3,
  // Ecommerce,
  // LogoDesigning,
  // MobileAppDevelopment,
  // PsdToHtml,
  // SEO,
  // SSL,
  // videoAnimation,
  // WebAppDevelopment,
  // webDesign,
  // websiteMaintenance,
  // WeOffer,

  // WebBanner1,
  // WebBanner2,
  // WebBanner3,
  // WebBanner4,

  // ECommerce1,
  // ECommerce2,
  // ECommerce3,
  // ECommerce4,
  // ECommerce5,
  // ECommerce6,

  // AppBanner1,
  // AppBanner2,
  // AppBanner3,
  // AppBanner4,
  // AppBanner5,
};

export default ImageHelper;
