import ImageHelper from "../../Helpers/ImageHelper";

const PortfolioDataOther = [
  {
    title: "CricBuzz Book",
    caption: "Book Cover",
    image: ImageHelper.Other1,
  },
  {
    title: "TZ Box Packing",
    caption: "Product Packing",
    image: ImageHelper.Other8,
  },
  {
    title: "FreshExpress Book",
    caption: "Book Cover",
    image: ImageHelper.Other2,
  },
  {
    title: "Lan Mania",
    caption: "Certificate",
    image: ImageHelper.Other10,
  },
  {
    title: "TZ Vouchers",
    caption: "Special Designs",
    image: ImageHelper.Other12,
  },
  {
    title: "Lan Mania",
    caption: "Certificate",
    image: ImageHelper.Other11,
  },
  {
    title: "Redken Hair Oil",
    caption: "Package Design",
    image: ImageHelper.Other4,
  },
  {
    title: "Topclues Solutions",
    caption: "SMM Picture",
    image: ImageHelper.Other6,
  },
  {
    title: "Redken Hair Oil",
    caption: "Package Design",
    image: ImageHelper.Other5,
  },
  {
    title: "TZ Box Packing",
    caption: "Product Packing",
    image: ImageHelper.Other7,
  },
  {
    title: "TZ Box Packing",
    caption: "Product Packing",
    image: ImageHelper.Other8,
  },
  {
    title: "TZ Box Packing",
    caption: "Product Packing",
    image: ImageHelper.Other9,
  },
];

export default PortfolioDataOther;
