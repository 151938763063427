import ImageHelper from "../../Helpers/ImageHelper";
import { SectionTitleHelper } from "../../Helpers/SectionTitleHelper";

export const AboutSectionData = {
  sectionTitle: SectionTitleHelper.aboutMe,
  description: [
    {
      id: 1,
      desc: "I am a seasoned professional with a Bachelor's degree in Computer Science and Engineering from India, specializing in Frontend Development and UI/UX Design.",
    },
    {
      id: 2,
      desc: "Dedicated, responsible, and adaptable, I bring a unique blend of flexibility to each project. My eagerness to learn is matched by my passion for crafting seamless code and captivating designs, evident in a lifestyle centered around these innovative pursuits. With a keen eye for detail, I draw inspiration from everyday life, constantly evolving my approach. My work is not just a job; it's a commitment to client satisfaction, where each project is an opportunity to deliver exceptional results.",
    },
    {
      id: 3,
      desc: "Until recently, I was employed as a Software Developer and Product Designer at a prominent service-based IT company, located in Bangalore, Karnataka. With over 6 years of experience in freelancing, I have actively pursued freelance work, collaborating with a wide range of clients on an array of exciting projects. My objective is to craft inventive solutions that not only captivate but also foster enduring connections between brands and their customer base. My primary focus on branding and crafting Elegant User Interfaces, delivering Unparalleled User Experiences, and Conceptualizing Mind-blowing Web Designs are driven by a resolute desire to produce seamless and polished products through meticulous and impassioned design.",
    },
    {
      id: 4,
      desc: "Following my departure from my previous role, I continued TZ Creation, my own IT company. My clientele spans from dynamic startups to established enterprises, and anyone in between to help them reach their goals without blowing their budget. In Addition to this, I successfully established 3 family ventures, two of them have shown promising performance. This journey led me to take a refreshing hiatus from the corporate world, returning to my home and relishing quality time with my family. Having recharged both mentally and emotionally, I am now invigorated and seeking new avenues to apply my skills and expertise.",
    },
  ],
  buttonData: {
    name: "Let's Connect",
    url: "https://www.linkedin.com/in/tabrezdal/",
  },
  sectionImage: ImageHelper?.AboutImage,
};
