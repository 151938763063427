import ImageHelper from "../../Helpers/ImageHelper";

const PortfolioDataSocialMediaDesign = [
  {
    title: "Madhav Products Post",
    caption: "Social Media Post",
    image: ImageHelper.SMP1,
  },
  {
    title: "Madhav Products Post",
    caption: "Social Media Post",
    image: ImageHelper.SMP2,
  },
  {
    title: "Madhav Products Post",
    caption: "Social Media Post",
    image: ImageHelper.SMP3,
  },
  {
    title: "Akshar Jewellery Luxury Touch",
    caption: "Social Media Post",
    image: ImageHelper.SMP4,
  },
  {
    title: "Akshar Jewellery with Model",
    caption: "Social Media Post",
    image: ImageHelper.SMP5,
  },
  {
    title: "Akshar Premium Jewellery",
    caption: "Social Media Post",
    image: ImageHelper.SMP6,
  },
  {
    title: "CVDRUS Black Diamond",
    caption: "Social Media Post",
    image: ImageHelper.SMP7,
  },
  {
    title: "CVDRUS LGD Diamond",
    caption: "Social Media Post",
    image: ImageHelper.SMP8,
  },
  {
    title: "CVDRUS Red Diamond",
    caption: "Social Media Post",
    image: ImageHelper.SMP9,
  },
  {
    title: "Muharram - Karbala Scene",
    caption: "Social Media Post",
    image: ImageHelper.SMPFull1,
    fullWidth: true,
  },
  {
    title: "Toran Hotel",
    caption: "Social Media Post",
    image: ImageHelper.SMP10,
  },
  {
    title: "Raninga Hospital",
    caption: "Social Media Post",
    image: ImageHelper.SMP11,
  },
  {
    title: "Bhagirathi Recidency",
    caption: "Social Media Post",
    image: ImageHelper.SMP12,
  },
  {
    title: "YourTaxi",
    caption: "Social Media Post",
    image: ImageHelper.SMP13,
  },
  {
    title: "Madhav Products",
    caption: "Social Media Post",
    image: ImageHelper.SMP14,
  },
  {
    title: "Madhav Post",
    caption: "Social Media Post",
    image: ImageHelper.SMP15,
  },
  {
    title: "Healthyffy Weight-loss",
    caption: "Social Media Post",
    image: ImageHelper.SMP16,
  },
  {
    title: "Healthyffy Juice Recipe",
    caption: "Social Media Post",
    image: ImageHelper.SMP17,
  },
  {
    title: "Healthyffy Weight-Loss",
    caption: "Social Media Post",
    image: ImageHelper.SMP18,
  },
  {
    title: "Healthyffy Balanced Diet",
    caption: "Social Media Post",
    image: ImageHelper.SMP19,
  },
  {
    title: "Uma Multispecialist Hospital",
    caption: "Social Media Post",
    image: ImageHelper.SMP20,
  },
  {
    title: "Healthful Diet",
    caption: "Social Media Post",
    image: ImageHelper.SMP21,
  },
  {
    title: "Topclues Post",
    caption: "Social Media Post",
    image: ImageHelper.SMP22,
  },
  {
    title: "Topclues Post",
    caption: "Social Media Post",
    image: ImageHelper.SMP23,
  },
  {
    title: "Topclues Post",
    caption: "Social Media Post",
    image: ImageHelper.SMP24,
  },
  {
    title: "Topclues Post",
    caption: "Social Media Post",
    image: ImageHelper.SMP25,
  },
  {
    title: "Topclues Post",
    caption: "Social Media Post",
    image: ImageHelper.SMP26,
  },
  {
    title: "Topclues Post",
    caption: "Social Media Post",
    image: ImageHelper.SMP27,
  },
  {
    title: "Dwrakesh Post",
    caption: "Social Media Post",
    image: ImageHelper.SMP28,
  },
  {
    title: "Dwrakesh Post",
    caption: "Social Media Post",
    image: ImageHelper.SMP29,
  },
  {
    title: "Dwrakesh Post",
    caption: "Social Media Post",
    image: ImageHelper.SMP30,
  },
  {
    title: "Dwrakesh Post",
    caption: "Social Media Post",
    image: ImageHelper.SMP31,
  },
  {
    title: "Dwrakesh Post",
    caption: "Social Media Post",
    image: ImageHelper.SMP32,
  },
  {
    title: "Dwrakesh Post",
    caption: "Social Media Post",
    image: ImageHelper.SMP33,
  },
  {
    title: "Dwrakesh Post",
    caption: "Social Media Post",
    image: ImageHelper.SMP34,
  },
  {
    title: "Dwrakesh Post",
    caption: "Social Media Post",
    image: ImageHelper.SMP35,
  },
  {
    title: "Dwrakesh Post",
    caption: "Social Media Post",
    image: ImageHelper.SMP36,
  },
  {
    title: "IG Trends",
    caption: "Social Media Post",
    image: ImageHelper.SMP37,
  },
  {
    title: "Instagram Profile Boost",
    caption: "Social Media Post",
    image: ImageHelper.SMP38,
  },
  {
    title: "Bhole Bhandari Restorant",
    caption: "Social Media Post",
    image: ImageHelper.SMP39,
  },
  {
    title: "Eid-Al-Adha",
    caption: "Social Media Post",
    image: ImageHelper.SMPFull2,
    fullWidth: true,
  },
  {
    title: "Women's Equality Day",
    caption: "Social Media Post",
    image: ImageHelper.SMP40,
  },
  {
    title: "Int. Literacy Day",
    caption: "Social Media Post",
    image: ImageHelper.SMP41,
  },
  {
    title: "Teacher's Day",
    caption: "Social Media Post",
    image: ImageHelper.SMP42,
  },
  {
    title: "Ganesh Chaturthi",
    caption: "Social Media Post",
    image: ImageHelper.SMP43,
  },
  {
    title: "Engineer's Day",
    caption: "Social Media Post",
    image: ImageHelper.SMP44,
  },
  {
    title: "Janmashtami",
    caption: "Social Media Post",
    image: ImageHelper.SMP45,
  },
  {
    title: "Akshar Jewel",
    caption: "Social Media Post",
    image: ImageHelper.SMP46,
  },
  {
    title: "Akshar Jewel",
    caption: "Social Media Post",
    image: ImageHelper.SMP47,
  },
  {
    title: "Akshar Jewel",
    caption: "Social Media Post",
    image: ImageHelper.SMP48,
  },
  {
    title: "Decoid",
    caption: "Social Media Post",
    image: ImageHelper.SMP49,
  },
  {
    title: "Decoid",
    caption: "Social Media Post",
    image: ImageHelper.SMP50,
  },
  {
    title: "Decoid",
    caption: "Social Media Post",
    image: ImageHelper.SMP51,
  },
  {
    title: "Gandhi Jayanti",
    caption: "Social Media Post",
    image: ImageHelper.SMP52,
  },
  {
    title: "Dhan Teras",
    caption: "Social Media Post",
    image: ImageHelper.SMP53,
  },
  {
    title: "Diwali Post",
    caption: "Social Media Post",
    image: ImageHelper.SMP54,
  },
  {
    title: "Jayvardhan Ghee",
    caption: "Social Media Post",
    image: ImageHelper.SMP55,
  },
  {
    title: "Nand Gval Ghee",
    caption: "Social Media Post",
    image: ImageHelper.SMP56,
  },
  {
    title: "Bhoomi Ghee",
    caption: "Social Media Post",
    image: ImageHelper.SMP57,
  },
  {
    title: "Dairy Ghee",
    caption: "Social Media Post",
    image: ImageHelper.SMP58,
  },
  {
    title: "Radhe Besan",
    caption: "Social Media Post",
    image: ImageHelper.SMP59,
  },
  {
    title: "Parv Snacks",
    caption: "Social Media Post",
    image: ImageHelper.SMP60,
  },
  {
    title: "Saya Oxy Bleach",
    caption: "Social Media Post",
    image: ImageHelper.SMP61,
  },
  {
    title: "Jax Balleza",
    caption: "Social Media Post",
    image: ImageHelper.SMP62,
  },
  {
    title: "Cubbit Cleaner",
    caption: "Social Media Post",
    image: ImageHelper.SMP63,
  },
  {
    title: "Baby Announcement",
    caption: "Social Media Post",
    image: ImageHelper.SMP64,
  },
  {
    title: "Cocobar Hair Oil",
    caption: "Social Media Post",
    image: ImageHelper.SMP65,
  },
  {
    title: "Baby Announcement",
    caption: "Social Media Post",
    image: ImageHelper.SMP66,
  },
  {
    title: "RoundUp Beverage",
    caption: "Social Media Post",
    image: ImageHelper.SMP67,
  },
  {
    title: "RoundUp Beverage",
    caption: "Social Media Post",
    image: ImageHelper.SMP68,
  },
  {
    title: "RoundUp Beverage",
    caption: "Social Media Post",
    image: ImageHelper.SMP69,
  },
  {
    title: "RoundUp Beverage",
    caption: "Social Media Post",
    image: ImageHelper.SMP70,
  },
  {
    title: "RoundUp Beverage",
    caption: "Social Media Post",
    image: ImageHelper.SMP71,
  },
  {
    title: "RoundUp Beverage",
    caption: "Social Media Post",
    image: ImageHelper.SMP72,
  },
  {
    title: "RoundUp Beverage",
    caption: "Social Media Post",
    image: ImageHelper.SMP73,
  },
  {
    title: "RoundUp Beverage",
    caption: "Social Media Post",
    image: ImageHelper.SMP74,
  },
  {
    title: "RoundUp Beverage",
    caption: "Social Media Post",
    image: ImageHelper.SMP75,
  },
  {
    title: "RoundUp Beverage",
    caption: "Social Media Post",
    image: ImageHelper.SMP76,
  },
  {
    title: "RoundUp Beverage",
    caption: "Social Media Post",
    image: ImageHelper.SMP77,
  },
  {
    title: "RoundUp Beverage",
    caption: "Social Media Post",
    image: ImageHelper.SMP78,
  },
  // {
  //   title: "SMM Post",
  //   caption: "Social Media Post",
  //   image: ImageHelper.SMP79,
  // },
  // {
  //   title: "SMM Post",
  //   caption: "Social Media Post",
  //   image: ImageHelper.SMP80,
  // },
  // {
  //   title: "SMM Post",
  //   caption: "Social Media Post",
  //   image: ImageHelper.SMP81,
  // },
  {
    title: "TZ Creation",
    caption: "Social Media Story",
    image: ImageHelper.SMP82,
  },
  {
    title: "Eid-Al-Adha",
    caption: "Social Media Story",
    image: ImageHelper.SMP83,
  },
  {
    title: "TZ Creation",
    caption: "Social Media Story",
    image: ImageHelper.SMP84,
  },
];

export default PortfolioDataSocialMediaDesign;
