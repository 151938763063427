const UiUxProjectPageData = {
  uiux01: {
    id: "u01",
    pageName: "Bidroid Old Ui",
    menuName: "Ui/Ux Projects",
    slug: "bidroid-old-ui",
  },
  uiux02: {
    id: "u02",
    pageName: "Bidroid New Ui",
    menuName: "Ui/Ux Projects",
    slug: "bidroid-new-ui",
  },
  uiux03: {
    id: "u03",
    pageName: "CricBuzz App Ui",
    menuName: "Ui/Ux Projects",
    slug: "cricbuzz-app-ui",
  },
  uiux04: {
    id: "u04",
    pageName: "Soy Hill Candle Co.",
    menuName: "Ui/Ux Projects",
    slug: "soy-hill-candle-co-1",
  },
  uiux05: {
    id: "u05",
    pageName: "Soy Hill Candle Co.",
    menuName: "Ui/Ux Projects",
    slug: "soy-hill-candle-co-2",
  },
  uiux06: {
    id: "u06",
    pageName: "Soy Hill Candle Co.",
    menuName: "Ui/Ux Projects",
    slug: "soy-hill-candle-co-3",
  },
  uiux07: {
    id: "u07",
    pageName: "Bidroid App",
    menuName: "Ui/Ux Projects",
    slug: "bidroid-app",
    ProjectLinks: {
      FigmaDesignLink:
        "https://www.figma.com/file/RbLUu3epsp6MNdS3Q97zea/Bidroid-App-by-TZ?type=design&node-id=1%3A14&mode=design&t=qLbKJkQyjwQ4fLV9-1",
      FigmaDesignEmmbedLink:
        "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FRbLUu3epsp6MNdS3Q97zea%2FBidroid-App-by-TZ%3Ftype%3Ddesign%26node-id%3D1%253A14%26mode%3Ddesign%26t%3DqLbKJkQyjwQ4fLV9-1",
      FigmaPrototypeLink:
        "https://www.figma.com/proto/RbLUu3epsp6MNdS3Q97zea/Bidroid-App-by-TZ?page-id=1%3A14&type=design&node-id=1-87&viewport=-695%2C3614%2C0.12&t=zHeaHRcMbEkrXC8m-1&scaling=scale-down&mode=design",
      FigmaPrototypeEmbbedLink:
        "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FRbLUu3epsp6MNdS3Q97zea%2FBidroid-App-by-TZ%3Fpage-id%3D1%253A14%26type%3Ddesign%26node-id%3D1-87%26viewport%3D-695%252C3614%252C0.12%26t%3DzHeaHRcMbEkrXC8m-1%26scaling%3Dscale-down%26mode%3Ddesign",
    },
    description: [
      {
        descriptionTitle: "",
        descriptionContent:
          "Bidroid App introduces a fresh perspective to online shopping through its innovative e-commerce auction store. At its core lies a captivating feature that allows users to place bids on their desired products, with a thrilling twist—victory is claimed by the lowest and most unique bid. This project serves as a captivating showcase of this groundbreaking bidding concept, providing a glimpse into the future of online shopping. Stay tuned for the upcoming detailed design phase, where the intricate layers of Bidroid's interface will be unveiled.",
      },
    ],
    buttonsGroup: {
      View: false,
      Download: false,
      Buy: false,
    },
    socialShareUrl: "https://tzcreation.in/ui-ux-project/uiux07",
    tags: [
      { tag: "E-commerce" },
      { tag: "Auctions" },
      { tag: "Bidding" },
      { tag: "Online Shopping" },
      { tag: "Concept" },
    ],
  },
  uiux08: {
    id: "u08",
    pageName: "Tabrez Dal - Expert Product Designer",
    menuName: "Ui/Ux Projects",
    slug: "tabrez-dal-expert-product-designer",
    ProjectLinks: {
      FigmaDesignLink:
        "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FuPwiXFAmUXMHsarV4GNSIc%2FFinverse-Guru-Revamped-by-TZ%3Ftype%3Ddesign%26node-id%3D0%253A1%26mode%3Ddesign%26t%3DYXkPKRHSMCpm8fNJ-1",
      FigmaDesignEmmbedLink:
        "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2F8pCFiznC4Hq0aj7nAjv3aG%2FTabrez-Dal---Expert-Product-Designer%3Ftype%3Ddesign%26node-id%3D0%253A1%26mode%3Ddesign%26t%3Ds52L1aRTqEaRiIM8-1",
      FigmaPrototypeLink:
        "https://www.figma.com/proto/8pCFiznC4Hq0aj7nAjv3aG/Tabrez-Dal---Expert-Product-Designer?page-id=0%3A1&type=design&node-id=1-2&viewport=225%2C250%2C0.17&t=rkyvMPIH8oKxGrgX-1&scaling=scale-down&mode=design",
      FigmaPrototypeEmbbedLink:
        "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2F8pCFiznC4Hq0aj7nAjv3aG%2FTabrez-Dal---Expert-Product-Designer%3Fpage-id%3D0%253A1%26type%3Ddesign%26node-id%3D1-2%26viewport%3D225%252C250%252C0.17%26t%3DrkyvMPIH8oKxGrgX-1%26scaling%3Dscale-down%26mode%3Ddesign",
    },
    description: [
      {
        descriptionTitle:
          "🎨 Hey there, I'm Tabrez Dal – Crafting Experiences, One Pixel at a Time",
        descriptionContent:
          "As someone who's been immersed in the world of design and development for over 4.5 years, I've embarked on an exciting new journey to share my passion with all of you. I'm thrilled to introduce myself as both a Product Designer and Frontend Developer who's all about turning ideas into captivating realities.",
      },
      {
        descriptionTitle: "🌟 Diving into Design Depth:",
        descriptionContent:
          "Design isn't just my job; it's my true calling. I've spent years perfecting the art of crafting UI/UX experiences that don't just look good, but also feel right. With a strong background in both frontend development and design, I'm all about striking that delicate balance between aesthetics and functionality.",
      },
      {
        descriptionTitle: "🚀 The Expertise You Deserve:",
        descriptionContent:
          "Why call myself an 'Expert Product Designer'? Because I pour my heart and soul into every project. From sleek user interfaces to seamless user experiences, I'm dedicated to creating designs that leave a lasting impact. Your users deserve nothing less than an extraordinary journey through your digital space, and that's exactly what I'm here to provide.",
      },
      {
        descriptionTitle: "📣 Stay in the Loop:",
        descriptionContent:
          "The countdown has begun! Be the first in line to experience design in a whole new light. Keep an eye out for updates – trust me, you won't want to miss what's coming next. Join me as I dive into pixels, lines of code, and boundless creativity. Let's shape digital experiences that resonate and inspire.",
      },
    ],
    buttonsGroup: {
      View: false,
      Download: false,
      Buy: false,
    },
    socialShareUrl: "https://tzcreation.in/ui-ux-project/uiux08",
    tags: [
      { tag: "Food Delivery" },
      { tag: "Food" },
      { tag: "Figma" },
      { tag: "Mobile App" },
      { tag: "Custom App Design" },
    ],
  },
  uiux09: {
    id: "u09",
    pageName: "TezHub",
    menuName: "Ui/Ux Projects",
    slug: "tezhub",
    ProjectLinks: {
      FigmaDesignLink:
        "https://www.figma.com/file/92E8ayZCG6pK6z0d5rVfNX/TezHUB-by-TZ?type=design&node-id=0%3A1&mode=design&t=jlchnmRhNK7OpwWH-1",
      FigmaDesignEmmbedLink:
        "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2F92E8ayZCG6pK6z0d5rVfNX%2FTezHUB-by-TZ%3Ftype%3Ddesign%26node-id%3D0%253A1%26mode%3Ddesign%26t%3DjlchnmRhNK7OpwWH-1",
      FigmaPrototypeLink:
        "https://www.figma.com/proto/92E8ayZCG6pK6z0d5rVfNX/TezHUB-by-TZ?page-id=0%3A1&type=design&node-id=596-24279&viewport=1620%2C1512%2C0.17&t=KNymyrRZ3NJL8b6p-1&scaling=scale-down&starting-point-node-id=596%3A24279&show-proto-sidebar=1&mode=design",
      FigmaPrototypeEmbbedLink:
        "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2F92E8ayZCG6pK6z0d5rVfNX%2FTezHUB-by-TZ%3Fpage-id%3D0%253A1%26type%3Ddesign%26node-id%3D596-24279%26viewport%3D1620%252C1512%252C0.17%26t%3DKNymyrRZ3NJL8b6p-1%26scaling%3Dscale-down%26starting-point-node-id%3D596%253A24279%26show-proto-sidebar%3D1%26mode%3Ddesign",
    },
    description: [
      {
        descriptionTitle:
          "TezHub: A Convergence of Services Across Platforms 🌐",
        descriptionContent:
          "TezHub stands as a testament to the power of integration, merging 7-8 distinct products into a singular, comprehensive solution. This ambitious project redefines convenience, offering users seamless access to a range of services across various domains from a single hub. From restaurant management and point-of-sale systems to digital signage, marketing, and loyalty programs, TezHub transcends platform barriers, ensuring a consistent experience for users irrespective of their chosen device.",
      },
      {
        descriptionTitle:
          "Empowering TezHub: From Diverse Products to Unified Innovation 🚀",
        descriptionContent:
          "This project was more than an interface overhaul; it marked a journey of technological evolution. Not only did it unite disparate products, but it also involved a coding metamorphosis. The aim was to propel the existing product line into the modern era, adopting contemporary technologies and approaches. TezHub's success heralds a new era of efficiency, accessibility, and innovation within the company's ecosystem.",
      },
    ],
    buttonsGroup: {
      View: false,
      Download: false,
      Buy: false,
    },
    socialShareUrl: "https://tzcreation.in/ui-ux-project/uiux09",
    tags: [
      { tag: "Platform Integration" },
      { tag: "Multiservice Hub" },
      { tag: "User Experience" },
      { tag: "Modernization" },
      { tag: "Custom Platform Design" },
    ],
  },
  // uiux10: {
  //   id: "u10",
  //   pageName: "Food Delivery App",
  //   menuName: "Ui/Ux Projects",
  //   slug: "food-delivery-app",
  //   ProjectLinks: {
  //     FigmaDesignLink:
  //       "https://www.figma.com/file/mxGgaGNGBk4UYv1foP0O4M/EatExpress-Food-Delivery-Customer-App?type=design&node-id=63%3A8445&mode=design&t=hCy0IwFDR5Im9Nct-1",
  //     FigmaDesignEmmbedLink:
  //       "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FmxGgaGNGBk4UYv1foP0O4M%2FEatExpress-Food-Delivery-Customer-App%3Ftype%3Ddesign%26node-id%3D63%253A8445%26mode%3Ddesign%26t%3DhCy0IwFDR5Im9Nct-1",
  //     FigmaPrototypeLink:
  //       "https://www.figma.com/proto/mxGgaGNGBk4UYv1foP0O4M/EatExpress-Food-Delivery-Customer-App?page-id=63%3A8445&type=design&node-id=104-11365&viewport=457%2C72%2C0.05&t=HmVvV7Ec3t7HGrvJ-1&scaling=min-zoom&starting-point-node-id=104%3A11365&show-proto-sidebar=1&mode=design",
  //     FigmaPrototypeEmbbedLink:
  //       "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FmxGgaGNGBk4UYv1foP0O4M%2FEatExpress-Food-Delivery-Customer-App%3Fpage-id%3D63%253A8445%26type%3Ddesign%26node-id%3D104-11365%26viewport%3D457%252C72%252C0.05%26t%3DHmVvV7Ec3t7HGrvJ-1%26scaling%3Dmin-zoom%26starting-point-node-id%3D104%253A11365%26show-proto-sidebar%3D1%26mode%3Ddesign",
  //   },
  //   description: [
  //     {
  //       descriptionTitle:
  //         "🍽️ Food Delivery App: Partnering for Culinary Convenience",
  //       descriptionContent:
  //         "Experience gastronomic delight through the Food Delivery App, meticulously divided into two distinct modules catering to both partners and customers. The first module delves into the intuitive design of the Food Delivery Partner app, offering seamless interactions for those bringing meals to your doorstep. The second module is dedicated to the Food Delivery Customer app, ensuring a delightful experience for those seeking culinary indulgence.",
  //     },
  //     {
  //       descriptionTitle:
  //         "🚀 Food Delivery App: Crafting Seamless Customer Experience",
  //       descriptionContent:
  //         "This design endeavor goes beyond the ordinary, incorporating a host of advanced features to elevate user engagement. With a comprehensive design system at its core, the app boasts auto-layout functionality, effortlessly adapting to various devices. Every aspect of food delivery is covered, ensuring a fluid and immersive experience. The integration of high-quality images and icons adds visual allure, while component variants with specific properties facilitate personalization.",
  //     },
  //     {
  //       descriptionTitle:
  //         "🎨 Food Delivery App: Design Delights and User-Friendly Feasts",
  //       descriptionContent:
  //         "The app doesn't just stand as a design masterpiece; it's a collaborative playground. Easy customization allows swift modifications across the design, making it developer, designer, and user-friendly. With a focus on the best user experience, the app is fully prototyped, offering interactive screens that provide a glimpse into the app's functionality.",
  //     },
  //   ],
  //   buttonsGroup: {
  //     View: false,
  //     Download: false,
  //     Buy: false,
  //   },
  //   socialShareUrl: "https://tzcreation.in/ui-ux-project/uiux10",
  //   tags: [
  //     { tag: "Food Delivery" },
  //     { tag: "Food Delivery Ui Kit" },
  //     { tag: "Restaurant Industry" },
  //     { tag: "Prototyping" },
  //     { tag: "Custom App Design" },
  //   ],
  // },
  uiux10: {
    id: "u10",
    pageName: "CasualChic- Casual Wear Store",
    menuName: "Ui/Ux Projects",
    slug: "casual-chic",
    ProjectLinks: {
      FigmaDesignLink:
        "https://www.figma.com/file/kgrvJ33U8tGgQrB7a8zBm5/ultras-casual-wear-ecommerce-figma-template?type=design&node-id=2%3A2&mode=design&t=BOsdJIVKogoeL4R9-1",
      FigmaDesignEmmbedLink:
        "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FkgrvJ33U8tGgQrB7a8zBm5%2Fultras-casual-wear-ecommerce-figma-template%3Ftype%3Ddesign%26node-id%3D2%253A2%26mode%3Ddesign%26t%3DBOsdJIVKogoeL4R9-1",
      FigmaPrototypeLink:
        "https://www.figma.com/proto/kgrvJ33U8tGgQrB7a8zBm5/ultras-casual-wear-ecommerce-figma-template?page-id=2%3A2&type=design&node-id=2-16&viewport=-74%2C1454%2C0.26&t=GiF8dw9hznw4PGHy-1&scaling=min-zoom&mode=design",
      FigmaPrototypeEmbbedLink:
        "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FkgrvJ33U8tGgQrB7a8zBm5%2Fultras-casual-wear-ecommerce-figma-template%3Fpage-id%3D2%253A2%26type%3Ddesign%26node-id%3D2-16%26viewport%3D-74%252C1454%252C0.26%26t%3DGiF8dw9hznw4PGHy-1%26scaling%3Dmin-zoom%26mode%3Ddesign",
    },
    description: [
      {
        descriptionTitle:
          "👗 CasualChic - Elevating Casual Wear to New Heights",
        descriptionContent:
          "Step into the world of CasualChic, where fashion meets comfort with a touch of elegance. Our Casual Wear Store is meticulously designed to offer you a curated collection that effortlessly blends relaxed style with a hint of sophistication. Explore a range of clothing and accessories that redefine casual fashion, making every day a fashionable and comfortable affair.",
      },
      {
        descriptionTitle: "🌟 Uncompromising Style and Quality",
        descriptionContent:
          "At CasualChic, we believe that you don't have to sacrifice style for comfort. Our collection features carefully selected pieces that are not only on-trend but also made from high-quality materials. Whether you're dressing for a casual day out or a laid-back evening, you'll find the perfect outfit that allows you to express your style while feeling comfortable.",
      },
      {
        descriptionTitle: "🛍️ Your One-Stop Destination for Effortless Fashion",
        descriptionContent:
          "Browse through our catalog of casual wear essentials, from cozy loungewear to chic everyday outfits. Our user-friendly website ensures a seamless shopping experience, and our commitment to design excellence means you'll enjoy browsing our collections just as much as you'll love wearing our products.",
      },
    ],
    buttonsGroup: {
      View: false,
      Download: false,
      Buy: false,
    },
    socialShareUrl: "https://tzcreation.in/ui-ux-project/uiux10",
    tags: [
      { tag: "E Commerce" },
      { tag: "Online Store" },
      { tag: "Garment Store" },
      { tag: "Single Page Design" },
      { tag: "Custom Store Design" },
    ],
  },
  uiux11: {
    id: "u11",
    pageName: "PropertyZone Landing Page",
    menuName: "Ui/Ux Projects",
    slug: "propertyZone-landing-page",
    ProjectLinks: {
      FigmaDesignLink:
        "https://www.figma.com/file/5Qks4oSg8FqMDMMOAUxh8Q/PropertyZone-Landing-Page-by-TZ?type=design&node-id=0%3A1&mode=design&t=2BkZ4AZd7RBhwacI-1",
      FigmaDesignEmmbedLink:
        "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2F5Qks4oSg8FqMDMMOAUxh8Q%2FPropertyZone-Landing-Page-by-TZ%3Ftype%3Ddesign%26node-id%3D0%253A1%26mode%3Ddesign%26t%3D2BkZ4AZd7RBhwacI-1",
      FigmaPrototypeLink:
        "https://www.figma.com/proto/5Qks4oSg8FqMDMMOAUxh8Q/PropertyZone-Landing-Page-by-TZ?page-id=0%3A1&type=design&node-id=2-2&viewport=494%2C190%2C0.08&t=bLp3baauLi1vLuNf-1&scaling=scale-down-width&starting-point-node-id=2%3A2&mode=design",
      FigmaPrototypeEmbbedLink:
        "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2F5Qks4oSg8FqMDMMOAUxh8Q%2FPropertyZone-Landing-Page-by-TZ%3Fpage-id%3D0%253A1%26type%3Ddesign%26node-id%3D2-2%26viewport%3D494%252C190%252C0.08%26t%3DbLp3baauLi1vLuNf-1%26scaling%3Dscale-down-width%26starting-point-node-id%3D2%253A2%26mode%3Ddesign",
    },
    description: [
      {
        descriptionTitle:
          "🏙️ PropertyZone Landing Page: Elegance Redefined for Dubai Real Estate",
        descriptionContent:
          "Welcome to the gateway of elegance in the real estate world - the PropertyZone Landing Page. Crafted for Dubai's renowned Property Zone firm, this landing page is a symphony of sophistication. Built on a component-based foundation, every element is meticulously designed with auto layout, ensuring flawless cohesion. Immerse yourself in a realm of luxury, where the finest properties come to life through high-quality images, elevating the user experience to unparalleled heights.",
      },
      {
        descriptionTitle:
          "🌟 Elevating User Experience: Crafting Luxury on the PropertyZone Landing Page",
        descriptionContent:
          "Step into a fully prototyped environment that not only showcases properties but also showcases excellence in interaction design. From seamless transitions to engaging animations, this landing page sets the bar for the best user interactions. This is more than just a page; it's an experience that encapsulates the essence of luxury living.",
      },
    ],
    buttonsGroup: {
      View: false,
      Download: false,
      Buy: false,
    },
    socialShareUrl: "https://tzcreation.in/ui-ux-project/uiux11",
    tags: [
      { tag: "Real Estate" },
      { tag: "Landing Page" },
      { tag: "Prototyping" },
      { tag: "User Experience" },
      { tag: "Luxury" },
    ],
  },
  uiux12: {
    id: "u12",
    pageName: "King Graphics Homepage",
    menuName: "Ui/Ux Projects",
    slug: "king-graphics-homepage",
    ProjectLinks: {
      FigmaDesignLink:
        "https://www.figma.com/file/2MWRQ5KYxaGVXZz5fw5KVz/King-Graphics-Task---4-by-Tabrez-Dal?type=design&node-id=7%3A0&mode=design&t=uTj5I1gM3K9nppEt-1",
      FigmaDesignEmmbedLink:
        "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2F2MWRQ5KYxaGVXZz5fw5KVz%2FKing-Graphics-Task---4-by-Tabrez-Dal%3Ftype%3Ddesign%26node-id%3D7%253A0%26mode%3Ddesign%26t%3DuTj5I1gM3K9nppEt-1",
      FigmaPrototypeLink:
        "https://www.figma.com/proto/2MWRQ5KYxaGVXZz5fw5KVz/King-Graphics-Task---4-by-Tabrez-Dal?page-id=0%3A1&type=design&node-id=7-0&viewport=-107%2C438%2C0.12&t=6m1aYwPGrOzXaDwG-1&scaling=scale-down-width&mode=design",
      FigmaPrototypeEmbbedLink:
        "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2F2MWRQ5KYxaGVXZz5fw5KVz%2FKing-Graphics-Task---4-by-Tabrez-Dal%3Fpage-id%3D0%253A1%26type%3Ddesign%26node-id%3D7-0%26viewport%3D-107%252C438%252C0.12%26t%3D6m1aYwPGrOzXaDwG-1%26scaling%3Dscale-down-width%26mode%3Ddesign",
    },
    description: [
      {
        descriptionTitle:
          "🖥️ King Graphics Homepage: Unveiling Digital Majesty for Dubai's Premier IT Firm",
        descriptionContent:
          "Step into the digital realm of innovation with the King Graphics Homepage—a gateway to Dubai's renowned IT powerhouse. This meticulously designed home page showcases a harmonious blend of aesthetics and technology. Embracing a component-based approach, every element resonates with precision through auto layout, ensuring a seamless and cohesive visual experience. The touch of luxury is evident in every pixel, reflecting the IT firm's reputation for excellence.",
      },
      {
        descriptionTitle:
          "🌐 Royalty in Design: Crafting Luxurious User Experiences on the King Graphics Homepage",
        descriptionContent:
          "Immerse yourself in the epitome of user experience as you navigate through a fully prototyped landscape. High-quality images and thoughtful interactions envelop you in a world where cutting-edge design meets technological prowess. This homepage isn't just a destination—it's a journey of exploration, a testament to the realm where design and IT converge.",
      },
    ],
    buttonsGroup: {
      View: false,
      Download: false,
      Buy: false,
    },
    socialShareUrl: "https://tzcreation.in/ui-ux-project/uiux12",
    tags: [
      { tag: "IT Company Website" },
      { tag: "Homepage" },
      { tag: "Prototyping" },
      { tag: "User Experience" },
      { tag: "Luxury" },
    ],
  },
  uiux13: {
    id: "u13",
    pageName: "MyHabit App Ui Design",
    menuName: "Ui/Ux Projects",
    slug: "myhabit-app-ui-design",
    ProjectLinks: {
      FigmaDesignLink:
        "https://www.figma.com/file/9HMIWUKmHtSMvLxLszUto4/MyHabit-App-Ui-Design-by-Tabrez?type=design&node-id=0%3A1&mode=design&t=wKtDUoJGhEdHoW9P-1",
      FigmaDesignEmmbedLink:
        "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2F9HMIWUKmHtSMvLxLszUto4%2FMyHabit-App-Ui-Design-by-Tabrez%3Ftype%3Ddesign%26node-id%3D0%253A1%26mode%3Ddesign%26t%3DwKtDUoJGhEdHoW9P-1",
      FigmaPrototypeLink:
        "https://www.figma.com/proto/9HMIWUKmHtSMvLxLszUto4/MyHabit-App-Ui-Design-by-Tabrez?page-id=0%3A1&type=design&node-id=37-168&viewport=460%2C283%2C0.02&t=xX4iq7k7RUaQHKjq-1&scaling=scale-down&starting-point-node-id=37%3A168&show-proto-sidebar=1&mode=design",
      FigmaPrototypeEmbbedLink:
        "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2F9HMIWUKmHtSMvLxLszUto4%2FMyHabit-App-Ui-Design-by-Tabrez%3Fpage-id%3D0%253A1%26type%3Ddesign%26node-id%3D37-168%26viewport%3D460%252C283%252C0.02%26t%3DxX4iq7k7RUaQHKjq-1%26scaling%3Dscale-down%26starting-point-node-id%3D37%253A168%26show-proto-sidebar%3D1%26mode%3Ddesign",
    },
    description: [
      {
        descriptionTitle:
          "📅 My Habit App: Elevating Progress Tracking with Elegant Simplicity",
        descriptionContent:
          "Elevate your journey of personal development with the My Habit App—a comprehensive solution for tracking habits and activities. Immerse yourself in a world of refined design as the app's black and white theme exudes elegance and simplicity. Navigating through the app is a breeze, thanks to its user-friendly interface that effortlessly guides you towards achieving your goals.",
      },
      {
        descriptionTitle:
          "🌟 Cultivating Success: Navigating Goals Seamlessly with My Habit App",
        descriptionContent:
          "Discover the synergy of tradition and modernity as the app integrates classic habit-tracking principles with the convenience of modern features. Each screen is thoughtfully prototyped, enabling you to preview the app's seamless functionality. With its elegant design, intuitive interface, and robust features, My Habit App is your ideal companion on the path to success.",
      },
    ],
    buttonsGroup: {
      View: false,
      Download: false,
      Buy: false,
    },
    socialShareUrl: "https://tzcreation.in/ui-ux-project/uiux13",
    tags: [
      { tag: "Habit Tracking" },
      { tag: "User Interface Design" },
      { tag: "Modern Features" },
      { tag: "Mobile App" },
      { tag: "User-Friendly" },
    ],
  },
  uiux14: {
    id: "u14",
    pageName: "LinkedUp",
    menuName: "Ui/Ux Projects",
    slug: "linkedup",
    ProjectLinks: {
      FigmaDesignLink:
        "https://www.figma.com/file/xFiOecWjbnLfa6oNFC29oe/Linkedup-App-Ui-Design-by-Tabrez-Dal?type=design&node-id=0%3A1&mode=design&t=5hZyAb4DBMGjvnc3-1",
      FigmaDesignEmmbedLink:
        "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FxFiOecWjbnLfa6oNFC29oe%2FLinkedup-App-Ui-Design-by-Tabrez-Dal%3Ftype%3Ddesign%26node-id%3D0%253A1%26mode%3Ddesign%26t%3D5hZyAb4DBMGjvnc3-1",
      FigmaPrototypeLink:
        "https://www.figma.com/proto/xFiOecWjbnLfa6oNFC29oe/Linkedup-App-Ui-Design-by-Tabrez-Dal?page-id=0%3A1&type=design&node-id=1-20021&viewport=570%2C692%2C0.05&t=LA2mL6kMTT4Dc9oF-1&scaling=scale-down&starting-point-node-id=1%3A14491&mode=design",
      FigmaPrototypeEmbbedLink:
        "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FxFiOecWjbnLfa6oNFC29oe%2FLinkedup-App-Ui-Design-by-Tabrez-Dal%3Fpage-id%3D0%253A1%26type%3Ddesign%26node-id%3D1-20021%26viewport%3D570%252C692%252C0.05%26t%3DLA2mL6kMTT4Dc9oF-1%26scaling%3Dscale-down%26starting-point-node-id%3D1%253A14491%26mode%3Ddesign",
    },
    description: [
      {
        descriptionTitle:
          "🚀 LinkedUp: Elevating Networking with Next-Gen Features",
        descriptionContent:
          "Drawing inspiration from existing platforms, LinkedUp takes a leap into the future, redefining connectivity with a modern UI and a host of innovative features. This enhanced version not only encompasses the familiar prowess of LinkedIn but also introduces groundbreaking functionalities to enrich the user experience.",
      },
      {
        descriptionTitle:
          "🌟 Redefining Profiles: Unveiling Enhanced User Engagement on LinkedUp",
        descriptionContent:
          "Embrace the power of personalized profiles with the introduction of Categories, allowing users to showcase their multifaceted personas. The job landscape evolves with a New Approach for Jobs and Internships, optimizing opportunities for both job seekers and employers. Scores, Awards & Honors, along with the Gratitude Wall, provide a platform for recognition and appreciation, fostering a culture of achievement.",
      },
      {
        descriptionTitle:
          "📚 Empowerment through Learning: Course Modules and Connectivity on LinkedUp",
        descriptionContent:
          "Experience the evolution of user and organizational profiles, with features that deepen engagement and interaction. The addition of a Creator Profile and Tools Module empowers content creators to shine. Delve into knowledge enhancement with a seamless Course Module, integrating learning journeys within the app.",
      },
      {
        descriptionTitle: "",
        descriptionContent:
          "From Auto Layout to comprehensive prototyping, every detail is meticulously designed to ensure a fluid and immersive experience. LinkedUp isn't just a networking platform; it's a glimpse into the future of connectivity.",
      },
    ],
    buttonsGroup: {
      View: false,
      Download: false,
      Buy: false,
    },
    socialShareUrl: "https://tzcreation.in/ui-ux-project/uiux14",
    tags: [
      { tag: "Social Networking" },
      { tag: "Innovation" },
      { tag: "Enhanced Features" },
      { tag: "Future Connectivity" },
      { tag: "Custom App Design" },
    ],
  },
  uiux15: {
    id: "u15",
    pageName: "Rush Invoice App",
    menuName: "Ui/Ux Projects",
    slug: "rush-invoice-app",
    ProjectLinks: {
      FigmaDesignLink:
        "https://www.figma.com/file/8tiS3VhfMk0OKuUdtUvBPn/Rush-Invoice-App-by-TZ?type=design&node-id=0%3A1&mode=design&t=br1hdWjR7jn68YSz-1",
      FigmaDesignEmmbedLink:
        "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2F8tiS3VhfMk0OKuUdtUvBPn%2FRush-Invoice-App-by-TZ%3Ftype%3Ddesign%26node-id%3D0%253A1%26mode%3Ddesign%26t%3Dbr1hdWjR7jn68YSz-1",
      FigmaPrototypeLink:
        "https://www.figma.com/proto/8tiS3VhfMk0OKuUdtUvBPn/Rush-Invoice-App-by-TZ?page-id=0%3A1&type=design&node-id=3-131&viewport=192%2C474%2C0.2&t=pE4MhDrzzrTdriOL-1&scaling=scale-down&starting-point-node-id=3%3A131&show-proto-sidebar=1&mode=design",
      FigmaPrototypeEmbbedLink:
        "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2F8tiS3VhfMk0OKuUdtUvBPn%2FRush-Invoice-App-by-TZ%3Fpage-id%3D0%253A1%26type%3Ddesign%26node-id%3D3-131%26viewport%3D192%252C474%252C0.2%26t%3DpE4MhDrzzrTdriOL-1%26scaling%3Dscale-down%26starting-point-node-id%3D3%253A131%26show-proto-sidebar%3D1%26mode%3Ddesign",
    },
    description: [
      {
        descriptionTitle:
          "💰 Rush Invoice App: Accelerating Invoicing and Business Management",
        descriptionContent:
          "Enter the world of seamless financial management with the Rush Invoice App—a dynamic solution born from a client's need for efficient invoicing and holistic business control. This app is a gateway to convenience, enabling end-users to swiftly generate invoices, expedite client communication, process payments, and efficiently manage both invoices and inventory.",
      },
      {
        descriptionTitle:
          "🌐 Seamlessness Redefined: Simplifying Invoices and Inventory with Rush Invoice App",
        descriptionContent:
          "Experience the blend of functionality and user-friendly design as you navigate through a comprehensive suite of tools. This app isn't just about invoices; it's a comprehensive platform that empowers businesses to streamline their financial operations. From creating invoices on the fly to tracking inventory, Rush Invoice App sets a new standard for business management.",
      },
    ],
    buttonsGroup: {
      View: false,
      Download: false,
      Buy: false,
    },
    socialShareUrl: "https://tzcreation.in/ui-ux-project/uiux15",
    tags: [
      { tag: "Invoice Management" },
      { tag: "Financial Efficiency" },
      { tag: "Business" },
      { tag: "Finance" },
      { tag: "Inventory Management" },
    ],
  },
  uiux16: {
    id: "u16",
    pageName: "moneyHop Registration Flow Design",
    menuName: "Ui/Ux Projects",
    slug: "moneyhop-registration-flow-design",
    ProjectLinks: {
      FigmaDesignLink:
        "https://www.figma.com/file/1Y3Qb1E7LnQxkM7LCksV9Z/moneyHop-Registration-Flow-Design-by-Tabrez-Dal?type=design&node-id=1%3A2910&mode=design&t=r9hKa1xHkCMrE759-1",
      FigmaDesignEmmbedLink:
        "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2F1Y3Qb1E7LnQxkM7LCksV9Z%2FmoneyHop-Registration-Flow-Design-by-Tabrez-Dal%3Ftype%3Ddesign%26node-id%3D1%253A2910%26mode%3Ddesign%26t%3Dr9hKa1xHkCMrE759-1",
      FigmaPrototypeLink:
        "https://www.figma.com/proto/1Y3Qb1E7LnQxkM7LCksV9Z/moneyHop-Registration-Flow-Design-by-Tabrez-Dal?page-id=1%3A2910&type=design&node-id=4-316&viewport=-188%2C368%2C0.13&t=kBJgxlbOiavaFd0n-1&scaling=min-zoom&starting-point-node-id=4%3A316&show-proto-sidebar=1&mode=design",
      FigmaPrototypeEmbbedLink:
        "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2F1Y3Qb1E7LnQxkM7LCksV9Z%2FmoneyHop-Registration-Flow-Design-by-Tabrez-Dal%3Fpage-id%3D1%253A2910%26type%3Ddesign%26node-id%3D4-316%26viewport%3D-188%252C368%252C0.13%26t%3DkBJgxlbOiavaFd0n-1%26scaling%3Dmin-zoom%26starting-point-node-id%3D4%253A316%26show-proto-sidebar%3D1%26mode%3Ddesign",
    },
    description: [
      {
        descriptionTitle:
          "📥 Seamlessly Joining: Transforming Onboarding with moneyHop Registration Flow",
        descriptionContent:
          "Witness the transformation of registration with the moneyHop Registration Flow Design—an endeavor to enhance user onboarding for an enriched financial journey. By revamping each screen and refining associated components from mere screenshots provided by the moneyHop team, this project is a testament to design's power.",
      },
      {
        descriptionTitle:
          "🌟 Designing Engagement: Elevating User Experience for moneyHop's Registration",
        descriptionContent:
          "Embrace a new level of user engagement as the registration flow takes on a fresh coat of innovation. With meticulous attention to detail, this redesign breathes life into the user experience. From simplifying steps to enhancing visual aesthetics, the moneyHop Registration Flow Design sets a benchmark for streamlined onboarding.",
      },
    ],
    buttonsGroup: {
      View: false,
      Download: false,
      Buy: false,
    },
    socialShareUrl: "https://tzcreation.in/ui-ux-project/uiux16",
    tags: [
      { tag: "Registration Flow" },
      { tag: "User Onboarding" },
      { tag: "Simplified Steps" },
      { tag: "Design Transformation" },
      { tag: "User Experience" },
    ],
  },
  uiux17: {
    id: "u17",
    pageName: "Kidzy Habit Tracker App Ui Concepts",
    menuName: "Ui/Ux Projects",
    slug: "kidzy-habit-tracker-app-ui-concepts",
    ProjectLinks: {
      FigmaDesignLink:
        "https://www.figma.com/proto/zywCljE4O6k6UkZHimGpqO/Kidzy-Habit-Tracker--App-Ui-Concepts-by-TZ?page-id=7%3A2&type=design&node-id=7-1578&viewport=1035%2C1179%2C0.18&t=VVbuWssFlK1rDWUN-1&scaling=scale-down&starting-point-node-id=7%3A1583&show-proto-sidebar=1&mode=design",
      FigmaDesignEmmbedLink:
        "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FzywCljE4O6k6UkZHimGpqO%2FKidzy-Habit-Tracker--App-Ui-Concepts-by-TZ%3Ftype%3Ddesign%26node-id%3D7%253A2%26mode%3Ddesign%26t%3DUMZR198FLgQddPuh-1",
      FigmaPrototypeLink:
        "https://www.figma.com/proto/zywCljE4O6k6UkZHimGpqO/Kids-Habit-App-UI-Concepts---TZ?page-id=0%3A1&type=design&node-id=1-43&viewport=267%2C519%2C0.4&t=kfS9sH2PTQnVP1mk-1&scaling=scale-down&mode=design",
      FigmaPrototypeEmbbedLink:
        "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FzywCljE4O6k6UkZHimGpqO%2FKidzy-Habit-Tracker--App-Ui-Concepts-by-TZ%3Fpage-id%3D7%253A2%26type%3Ddesign%26node-id%3D7-1578%26viewport%3D1035%252C1179%252C0.18%26t%3DVVbuWssFlK1rDWUN-1%26scaling%3Dscale-down%26starting-point-node-id%3D7%253A1583%26show-proto-sidebar%3D1%26mode%3Ddesign",
    },
    description: [
      {
        descriptionTitle:
          "🌈 Kidzy Habit Tracker App UI Concepts: Fostering Healthy Habits with Creative Designs",
        descriptionContent:
          "Embark on a journey of creativity with the Kidzy Habit Tracker App UI Concepts—a collection crafted for a Freelancer Portal competition. This innovative set of designs reimagines the world of Habit/Activity Tracking Apps through a child-friendly lens. Five distinct concepts were meticulously conceptualized, reflecting a range of approaches to engage and inspire young users.",
      },
      {
        descriptionTitle:
          "🎨 Inspiring Engagement: Exploring Winning Concepts for Kidzy Habit Tracker App",
        descriptionContent:
          "Two standout concepts emerged victorious, capturing the essence of the competition's theme. The Kidzy Habit Tracker App UI Concepts encompass a vibrant array of visual styles, catering to the dynamic preferences of young users. From playful interactions to intuitive layouts, each concept presents a unique canvas for cultivating healthy routines in children.",
      },
    ],
    buttonsGroup: {
      View: false,
      Download: false,
      Buy: false,
    },
    socialShareUrl: "https://tzcreation.in/ui-ux-project/uiux17",
    tags: [
      { tag: "UI Concepts" },
      { tag: "Habit Tracking" },
      { tag: "Child-Friendly Design" },
      { tag: "Freelancer Competition" },
      { tag: "Playful Interactions" },
    ],
  },
  uiux18: {
    id: "u18",
    pageName: "SOS Manager App Single Screen",
    menuName: "Ui/Ux Projects",
    slug: "sos-manager-app-single-screen",
    ProjectLinks: {
      FigmaDesignLink:
        "https://www.figma.com/file/mIF2TzoSD6DjVdhLYhTWg4/SOS-Emergency-App---TZ?type=design&node-id=0%3A1&mode=design&t=IwAt6BJswdJMY9vQ-1",
      FigmaDesignEmmbedLink:
        "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FmIF2TzoSD6DjVdhLYhTWg4%2FSOS-Emergency-App---TZ%3Ftype%3Ddesign%26node-id%3D0%253A1%26mode%3Ddesign%26t%3DIwAt6BJswdJMY9vQ-1",
      FigmaPrototypeLink:
        "https://www.figma.com/proto/mIF2TzoSD6DjVdhLYhTWg4/SOS-Emergency-App---TZ?page-id=0%3A1&type=design&node-id=2-29&viewport=-1646%2C-198%2C0.25&t=C8YZ0CYJemxPiILV-1&scaling=scale-down&mode=design",
      FigmaPrototypeEmbbedLink:
        "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FmIF2TzoSD6DjVdhLYhTWg4%2FSOS-Emergency-App---TZ%3Fpage-id%3D0%253A1%26type%3Ddesign%26node-id%3D2-29%26viewport%3D-1646%252C-198%252C0.25%26t%3DC8YZ0CYJemxPiILV-1%26scaling%3Dscale-down%26mode%3Ddesign",
    },
    description: [
      {
        descriptionTitle:
          "🆘 SOS Manager App: Instant Help Through a Single Screen",
        descriptionContent:
          "Introducing the SOS Manager App's Single Screen—a beacon of hope in emergency situations. This user-centric interface empowers individuals with a lifeline to their favorite contacts, ensuring quick access to aid when time is of the essence. Seamlessly sending audio, video, voice recordings, and locations, the app simplifies the process through intuitive gestures, offering a streamlined response.",
      },
      {
        descriptionTitle:
          "🌟 Rapid Response: Unveiling the Power of SOS Manager App's Main Screen",
        descriptionContent:
          "The main screen's design embodies efficiency and urgency, providing a glimpse of the forthcoming comprehensive flow. This app stands as a testament to technology's ability to safeguard lives, epitomizing the power of a simple gesture in times of need.",
      },
    ],
    buttonsGroup: {
      View: false,
      Download: false,
      Buy: false,
    },
    socialShareUrl: "https://tzcreation.in/ui-ux-project/uiux18",
    tags: [
      { tag: "Emergency App" },
      { tag: "SOS Manager" },
      { tag: "User Interface" },
      { tag: "Quick Aid" },
      { tag: "Custom App Design" },
    ],
  },
  uiux19: {
    id: "u19",
    pageName: "MenOnline",
    menuName: "Ui/Ux Projects",
    slug: "menonline",
    ProjectLinks: {
      FigmaDesignLink:
        "https://www.figma.com/file/fk5h0eLTzisiDKps17PweI/MenuOnline-by-TZ?type=design&node-id=0%3A1&mode=design&t=RSOySh5J75YcPzlK-1",
      FigmaDesignEmmbedLink:
        "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2Ffk5h0eLTzisiDKps17PweI%2FMenuOnline-by-TZ%3Ftype%3Ddesign%26node-id%3D0%253A1%26mode%3Ddesign%26t%3DRSOySh5J75YcPzlK-1",
      FigmaPrototypeLink:
        "https://www.figma.com/proto/fk5h0eLTzisiDKps17PweI/MenuOnline-by-TZ?page-id=0%3A1&type=design&node-id=120-5545&viewport=66%2C81%2C0.03&t=hqTUUCkTzqfGzble-1&scaling=scale-down&starting-point-node-id=120%3A5545&show-proto-sidebar=1&mode=design",
      FigmaPrototypeEmbbedLink:
        "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2Ffk5h0eLTzisiDKps17PweI%2FMenuOnline-by-TZ%3Fpage-id%3D0%253A1%26type%3Ddesign%26node-id%3D120-5545%26viewport%3D66%252C81%252C0.03%26t%3DhqTUUCkTzqfGzble-1%26scaling%3Dscale-down%26starting-point-node-id%3D120%253A5545%26show-proto-sidebar%3D1%26mode%3Ddesign",
    },
    description: [
      {
        descriptionTitle:
          "🍴 MenOnline: Crafting Seamless Dining Experiences with SAAS Brilliance",
        descriptionContent:
          "Introducing MenOnline—a cutting-edge SAAS product meticulously tailored for the restaurant industry. This comprehensive platform presents a seamless fusion of innovation and convenience, providing an all-encompassing solution for both patrons and restaurateurs.",
      },
      {
        descriptionTitle:
          "🌐 Reshaping Dining: Exploring the Innovative Booking Platform of MenOnline",
        descriptionContent:
          "Step into the future of dining with the MenOnline Booking Platform, where customers can effortlessly reserve tables, place orders, settle bills, and indulge in a range of conveniences. This dynamic interface transforms dining into an immersive experience, bringing unprecedented ease and enjoyment to every meal.",
      },
      {
        descriptionTitle:
          "⚙️ Streamlined Operations: Unveiling MenOnline's Backend Magic for Restaurants",
        descriptionContent:
          "Behind the scenes, the MenOnline Backend empowers restaurant owners to manage operations with precision. Seamlessly track and manage orders, receive payments, allocate tables, and execute an array of administrative tasks that elevate efficiency and customer satisfaction.",
      },
      {
        descriptionTitle: "📣 Stay in the Loop:",
        descriptionContent:
          "The countdown has begun! Be the first in line to experience design in a whole new light. Keep an eye out for updates – trust me, you won't want to miss what's coming next. Join me as I dive into pixels, lines of code, and boundless creativity. Let's shape digital experiences that resonate and inspire.",
      },
    ],
    buttonsGroup: {
      View: false,
      Download: false,
      Buy: false,
    },
    socialShareUrl: "https://tzcreation.in/ui-ux-project/uiux19",
    tags: [
      { tag: "SAAS Product" },
      { tag: "Restaurant Industry" },
      { tag: "Booking Platform" },
      { tag: "Operational Efficiency" },
      { tag: "Frontend and Backend Design" },
    ],
  },
  uiux20: {
    id: "u20",
    pageName: "LevelNext Web",
    menuName: "Ui/Ux Projects",
    slug: "levelNext-web",
    ProjectLinks: {
      FigmaDesignLink:
        "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2Ffk5h0eLTzisiDKps17PweI%2FMenuOnline-by-TZ%3Fpage-id%3D0%253A1%26type%3Ddesign%26node-id%3D120-5545%26viewport%3D66%252C81%252C0.03%26t%3DhqTUUCkTzqfGzble-1%26scaling%3Dscale-down%26starting-point-node-id%3D120%253A5545%26show-proto-sidebar%3D1%26mode%3Ddesign",
      FigmaDesignEmmbedLink:
        "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2F8XNj6n9qB4YMhmgcwxtmcy%2FLevelNext-Web-by-TZ%3Ftype%3Ddesign%26node-id%3D0%253A1%26mode%3Ddesign%26t%3DgK9PPW9o6oTjc5UF-1",
      FigmaPrototypeLink:
        "https://www.figma.com/proto/8XNj6n9qB4YMhmgcwxtmcy/LevelNext-Web-by-TZ?page-id=0%3A1&type=design&node-id=31-85&viewport=598%2C505%2C0.04&t=rBNObHsKMBeQMOSu-1&scaling=scale-down-width&starting-point-node-id=31%3A85&show-proto-sidebar=1&mode=design",
      FigmaPrototypeEmbbedLink:
        "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2F8XNj6n9qB4YMhmgcwxtmcy%2FLevelNext-Web-by-TZ%3Fpage-id%3D0%253A1%26type%3Ddesign%26node-id%3D31-85%26viewport%3D598%252C505%252C0.04%26t%3DrBNObHsKMBeQMOSu-1%26scaling%3Dscale-down-width%26starting-point-node-id%3D31%253A85%26show-proto-sidebar%3D1%26mode%3Ddesign",
    },
    description: [
      {
        descriptionTitle:
          "📚 LevelNext Web: Empowering Careers with Expert Mentorship and Training",
        descriptionContent:
          "Introducing LevelNext Web—a dynamic web platform designed to catalyze personal and professional growth. This hub of knowledge offers users a gateway to mentorship, training, and transformative courses. Empowering individuals to ascend in their careers and businesses, LevelNext Web taps into the wisdom of industry experts, providing invaluable guidance and knowledge.",
      },
      {
        descriptionTitle:
          "🎯 Elevating Aspirations: Navigating the Path to Success with LevelNext's Landing Page",
        descriptionContent:
          "The landing page serves as an inviting introduction to a world of opportunity. With a seamless blend of aesthetics and functionality, it offers a glimpse into the comprehensive offerings awaiting users. LevelNext Web isn't just a platform; it's a stepping stone towards unlocking potential and realizing aspirations.",
      },
    ],
    buttonsGroup: {
      View: false,
      Download: false,
      Buy: false,
    },
    socialShareUrl: "https://tzcreation.in/ui-ux-project/uiux20",
    tags: [
      { tag: "Web Platform" },
      { tag: "Mentorship" },
      { tag: "Training" },
      { tag: "Career Growth" },
      { tag: "Landing Page Design" },
    ],
  },
  uiux21: {
    id: "u21",
    pageName: "MenOnline Foundation",
    menuName: "Ui/Ux Projects",
    slug: "menOnline-foundation",
    ProjectLinks: {
      FigmaDesignLink:
        "https://www.figma.com/file/QjEFo7gJEaGw3954dPWvfL/MenuOnline-Foundation-by-TZ?type=design&node-id=0%3A1&mode=design&t=WjsRMs8bOjL4SFId-1",
      FigmaDesignEmmbedLink:
        "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FQjEFo7gJEaGw3954dPWvfL%2FMenuOnline-Foundation-by-TZ%3Ftype%3Ddesign%26node-id%3D0%253A1%26mode%3Ddesign%26t%3DWjsRMs8bOjL4SFId-1",
      FigmaPrototypeLink:
        "https://www.figma.com/proto/QjEFo7gJEaGw3954dPWvfL/MenuOnline-Foundation-by-TZ?page-id=0%3A1&type=design&node-id=74-5306&viewport=21%2C173%2C0.03&t=SgFSqHK8HUsBSCIv-1&scaling=scale-down&starting-point-node-id=74%3A5306&show-proto-sidebar=1&mode=design",
      FigmaPrototypeEmbbedLink:
        "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FQjEFo7gJEaGw3954dPWvfL%2FMenuOnline-Foundation-by-TZ%3Fpage-id%3D0%253A1%26type%3Ddesign%26node-id%3D74-5306%26viewport%3D21%252C173%252C0.03%26t%3DSgFSqHK8HUsBSCIv-1%26scaling%3Dscale-down%26starting-point-node-id%3D74%253A5306%26show-proto-sidebar%3D1%26mode%3Ddesign",
    },
    description: [
      {
        descriptionTitle:
          "🍽️ MenOnline Foundation: Pioneering Culinary Excellence with Comprehensive SAAS",
        descriptionContent:
          "Unveil the cornerstone of dining innovation with MenOnline Foundation—a comprehensive SAAS product meticulously sculpted for the restaurant industry. This visionary solution embodies the full spectrum of the dining experience, seamlessly merging functionality and aesthetics to serve as the bedrock for culinary excellence.",
      },
      {
        descriptionTitle:
          "🌐 Seamless Dining Symphony: Unveiling MenOnline Foundation's Visionary Platform",
        descriptionContent:
          "The heart of MenOnline Foundation beats with the Booking Platform, where patrons are offered an immersive journey of table reservations, ordering delicacies, and settling bills—all with intuitive ease. Beyond the frontend, the robust Backend Management sets a new standard in operational efficiency. From tracking and managing orders to seamless payment processing, it empowers restaurateurs to orchestrate flawless dining experiences.",
      },
      {
        descriptionTitle:
          "⚙️ Orchestrating Flawless Experiences: Exploring MenOnline Foundation's Backend Mastery",
        descriptionContent:
          "This is more than a SAAS product; it's a revolution that empowers businesses to flourish, diners to indulge, and the entire industry to embrace the future with open arms.",
      },
    ],
    buttonsGroup: {
      View: false,
      Download: false,
      Buy: false,
    },
    socialShareUrl: "https://tzcreation.in/ui-ux-project/uiux21",
    tags: [
      { tag: "SAAS Product" },
      { tag: "Restaurant Industry" },
      { tag: "Booking Platform" },
      { tag: "Operational Efficiency" },
      { tag: "Seamless Experience" },
    ],
  },
  uiux22: {
    id: "u22",
    pageName: "Finverse Guru Revamped",
    menuName: "Ui/Ux Projects",
    slug: "finverse-guru-revamped",
    ProjectLinks: {
      FigmaDesignLink:
        "https://www.figma.com/file/uPwiXFAmUXMHsarV4GNSIc/Finverse-Guru-Revamped-by-TZ?type=design&node-id=0%3A1&mode=design&t=YXkPKRHSMCpm8fNJ-1",
      FigmaDesignEmmbedLink:
        "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FuPwiXFAmUXMHsarV4GNSIc%2FFinverse-Guru-Revamped-by-TZ%3Ftype%3Ddesign%26node-id%3D0%253A1%26mode%3Ddesign%26t%3DYXkPKRHSMCpm8fNJ-1",
      FigmaPrototypeLink:
        "https://www.figma.com/proto/uPwiXFAmUXMHsarV4GNSIc/Finverse-Guru-Revamped-by-TZ?page-id=0%3A1&type=design&node-id=10-2798&viewport=394%2C463%2C0.07&t=dX4NuHlGFpJhrlGj-1&scaling=scale-down&starting-point-node-id=10%3A2798&show-proto-sidebar=1&mode=design",
      FigmaPrototypeEmbbedLink:
        "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FuPwiXFAmUXMHsarV4GNSIc%2FFinverse-Guru-Revamped-by-TZ%3Fpage-id%3D0%253A1%26type%3Ddesign%26node-id%3D10-2798%26viewport%3D394%252C463%252C0.07%26t%3DdX4NuHlGFpJhrlGj-1%26scaling%3Dscale-down%26starting-point-node-id%3D10%253A2798%26show-proto-sidebar%3D1%26mode%3Ddesign",
    },
    description: [
      {
        descriptionTitle:
          "📚 Finverse Guru Revamped: Uniting Minds for Financial Enlightenment",
        descriptionContent:
          "Discover the transformed Finverse Guru—a platform that unites students, academics, professionals, and experts in a collaborative journey toward financial enlightenment. With a fresh perspective, the homepage and associated pages now embody the essence of financial wisdom and community engagement.",
      },
      {
        descriptionTitle:
          "🌟 Navigating Financial Insights: The Enhanced World of Finverse Guru",
        descriptionContent:
          "Embark on a seamless exploration of finance with the Finverse Guru homepage and associated pages. This revamped platform gathers a community of individuals driven by a passion for learning, growth, and financial wisdom. As you navigate through the thoughtfully crafted interface, you'll connect with like-minded individuals and tap into the expertise of professionals and experts. The design transformation is more than a visual overhaul; it's a gateway to a thriving ecosystem built on collaboration and shared knowledge.",
      },
    ],
    buttonsGroup: {
      View: false,
      Download: false,
      Buy: false,
    },
    socialShareUrl: "https://tzcreation.in/ui-ux-project/uiux22",
    tags: [
      { tag: "Financial Platform" },
      { tag: "Collaboration" },
      { tag: "Knowledge Sharing" },
      { tag: "Expert Guidance" },
      { tag: "Homepage Design" },
    ],
  },
  uiux23: {
    id: "u23",
    pageName: "FamilyOffice360",
    menuName: "Ui/Ux Projects",
    slug: "family-office-360",
    ProjectLinks: {
      FigmaDesignLink:
        "https://www.figma.com/file/U8iJ98CkIeuUfx2Z8gODvR/360FamilyOffice?type=design&node-id=0%3A1&mode=design&t=R81Znbi1TbQPVH3K-1",
      FigmaDesignEmmbedLink:
        "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FU8iJ98CkIeuUfx2Z8gODvR%2FFamilyOffice360%3Ftype%3Ddesign%26node-id%3D0%253A1%26mode%3Ddesign%26t%3DR81Znbi1TbQPVH3K-1",
      FigmaPrototypeLink:
        "https://www.figma.com/proto/U8iJ98CkIeuUfx2Z8gODvR/FamilyOffice360?page-id=0%3A1&type=design&node-id=1-922&viewport=55%2C333%2C0.03&t=rZD9ngslLgH0eyD6-1&scaling=scale-down-width&starting-point-node-id=1%3A922&show-proto-sidebar=1&mode=design",
      FigmaPrototypeEmbbedLink:
        "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FU8iJ98CkIeuUfx2Z8gODvR%2FFamilyOffice360%3Fpage-id%3D0%253A1%26type%3Ddesign%26node-id%3D1-922%26viewport%3D55%252C333%252C0.03%26t%3DrZD9ngslLgH0eyD6-1%26scaling%3Dscale-down-width%26starting-point-node-id%3D1%253A922%26show-proto-sidebar%3D1%26mode%3Ddesign",
    },
    description: [
      {
        descriptionTitle:
          "🌟 Designing Wealth and Legacy: My Journey with FamilyOffice360",
        descriptionContent:
          "As a designer, I brought FamilyOffice360's vision to life through intuitive web design. With two distinct website variations, I aimed to convey trust and reliability while recognizing that a family office is a custodian of dreams and legacies.",
      },
    ],
    buttonsGroup: {
      View: false,
      Download: false,
      Buy: false,
    },
    socialShareUrl: "https://tzcreation.in/ui-ux-project/uiux23",
    tags: [
      { tag: "Web Design" },
      { tag: "Financial Services" },
      { tag: "User Experience Design" },
      { tag: "Wealth Management" },
      { tag: "Figma UI/UX" },
    ],
  },
  uiux24: {
    id: "u24",
    pageName: "Sundesh Solar Website Revamp",
    menuName: "Ui/Ux Projects",
    slug: "sundesh-solar-website-revamp",
    ProjectLinks: {
      FigmaDesignLink:
        "https://www.figma.com/file/KMqHhUfaWwFEGJuQftQzi1/Sundesh-Solar-Website-Revamp?type=design&node-id=0%3A1&mode=design&t=JqB80ZKzAkBHbXCD-1",
      FigmaDesignEmmbedLink:
        "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FKMqHhUfaWwFEGJuQftQzi1%2FSundesh-Solar-Website-Revamp%3Ftype%3Ddesign%26node-id%3D0%253A1%26mode%3Ddesign%26t%3DJqB80ZKzAkBHbXCD-1",
      FigmaPrototypeLink:
        "https://www.figma.com/proto/KMqHhUfaWwFEGJuQftQzi1/Sundesh-Solar-Website-Revamp?page-id=0%3A1&type=design&node-id=4-2325&viewport=246%2C357%2C0.12&t=CJbppQ4SPrkf54hP-1&scaling=scale-down-width&starting-point-node-id=4%3A2325&show-proto-sidebar=1&mode=design",
      FigmaPrototypeEmbbedLink:
        "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FKMqHhUfaWwFEGJuQftQzi1%2FSundesh-Solar-Website-Revamp%3Fpage-id%3D0%253A1%26type%3Ddesign%26node-id%3D4-2325%26viewport%3D246%252C357%252C0.12%26t%3DCJbppQ4SPrkf54hP-1%26scaling%3Dscale-down-width%26starting-point-node-id%3D4%253A2325%26show-proto-sidebar%3D1%26mode%3Ddesign",
    },
    description: [
      {
        descriptionTitle:
          "☀️ Modernizing the Solar Frontier: Sundesh Solar Website Revamp",
        descriptionContent:
          "As a UI/UX designer, I undertook the task of revamping the Sundesh Solar website, a journey that aimed to modernize their online presence. My primary focus was on the homepage, the digital face of Sundesh Solar, where first impressions matter the most.",
      },
      {
        descriptionTitle:
          "🌞 Digital Radiance: Crafting Three Unique Variants for Sundesh Solar's Homepage",
        descriptionContent:
          "I meticulously designed three distinct variants for the homepage, each with its unique aesthetic and functionality. These variants were crafted to offer visitors a visually captivating experience while ensuring easy navigation and access to essential information. The goal was not just to revamp a website but to elevate Sundesh Solar's digital brand image and provide a platform that reflects their commitment to sustainable energy solutions.",
      },
    ],
    buttonsGroup: {
      View: false,
      Download: false,
      Buy: false,
    },
    socialShareUrl: "https://tzcreation.in/ui-ux-project/uiux24",
    tags: [
      { tag: "Website Revamp" },
      { tag: "Homepage Design" },
      { tag: "Sustainability" },
      { tag: "Solar Energy" },
      { tag: "UI/UX Design" },
    ],
  },
  uiux25: {
    id: "u25",
    pageName: "A1 Visas Passport Photo Service Landing Page",
    menuName: "Ui/Ux Projects",
    slug: "a1-visas-passport-photo-service-landing-page",
    ProjectLinks: {
      FigmaDesignLink:
        "https://www.figma.com/file/GP3XBvWHv60gniVIEslUDB/A1-Visas-Passport-Photo-Service-Landing-Page?type=design&node-id=0%3A1&mode=design&t=Z5mQr4jvO92aLwqt-1",
      FigmaDesignEmmbedLink:
        "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FGP3XBvWHv60gniVIEslUDB%2FA1-Visas-Passport-Photo-Service-Landing-Page%3Ftype%3Ddesign%26node-id%3D0%253A1%26mode%3Ddesign%26t%3DZ5mQr4jvO92aLwqt-1",
      FigmaPrototypeLink:
        "https://www.figma.com/proto/GP3XBvWHv60gniVIEslUDB/A1-Visas-Passport-Photo-Service-Landing-Page?page-id=0%3A1&type=design&node-id=1-2&viewport=-404%2C176%2C0.19&t=GJjMTBWPHfDjGDgp-1&scaling=min-zoom&starting-point-node-id=1%3A2&mode=design",
      FigmaPrototypeEmbbedLink:
        "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FGP3XBvWHv60gniVIEslUDB%2FA1-Visas-Passport-Photo-Service-Landing-Page%3Fpage-id%3D0%253A1%26type%3Ddesign%26node-id%3D1-2%26viewport%3D-404%252C176%252C0.19%26t%3DGJjMTBWPHfDjGDgp-1%26scaling%3Dmin-zoom%26starting-point-node-id%3D1%253A2%26mode%3Ddesign",
    },
    description: [
      {
        descriptionTitle:
          "🌟 Crafting User-Centric Design: A1 Visas Passport Photo Service Landing Page",
        descriptionContent:
          "As a UI/UX designer, my mission was to translate your client's vision into a seamlessly functional landing page. The A1 Visas Passport Photo Service is your passport to hassle-free global travel, with country-specific photo solutions that are guaranteed to be accepted.",
      },
      {
        descriptionTitle:
          "📸 Simplifying Global Travel: Your Passport to Hassle-Free Photos",
        descriptionContent:
          "Our user-centric landing page design focuses on simplicity and efficiency, ensuring that users can quickly access the services they need. Whether it's a U.S. visa or any other international travel document, I've designed this page to be intuitive and user-friendly, so travelers can get their photos with ease.",
      },
    ],
    buttonsGroup: {
      View: false,
      Download: false,
      Buy: false,
    },
    socialShareUrl: "https://tzcreation.in/ui-ux-project/uiux25",
    tags: [
      { tag: "Passport Photos" },
      { tag: "User-Centric Design" },
      { tag: "User Experience Design" },
      { tag: "Landing Page" },
      { tag: "UI/UX Design" },
    ],
  },
  uiux26: {
    id: "u26",
    pageName: "Artyss",
    menuName: "Ui/Ux Projects",
    slug: "artyss",
    ProjectLinks: {
      FigmaDesignLink:
        "https://www.figma.com/file/GVMlNLbc0a7nlteT4mYhgi/Artyss?type=design&node-id=0%3A1&mode=design&t=IVjFjXScZF8NYC1n-1",
      FigmaDesignEmmbedLink:
        "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FGVMlNLbc0a7nlteT4mYhgi%2FArtyss%3Ftype%3Ddesign%26node-id%3D0%253A1%26mode%3Ddesign%26t%3DIVjFjXScZF8NYC1n-1",
      FigmaPrototypeLink:
        "https://www.figma.com/proto/GVMlNLbc0a7nlteT4mYhgi/Artyss?page-id=0%3A1&type=design&node-id=60-58&viewport=-82%2C60%2C0.09&t=AqQs6o1k0vc0vUu1-1&scaling=scale-down-width&starting-point-node-id=60%3A58&show-proto-sidebar=1&mode=design",
      FigmaPrototypeEmbbedLink:
        "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FGVMlNLbc0a7nlteT4mYhgi%2FArtyss%3Fpage-id%3D0%253A1%26type%3Ddesign%26node-id%3D60-58%26viewport%3D-82%252C60%252C0.09%26t%3DAqQs6o1k0vc0vUu1-1%26scaling%3Dscale-down-width%26starting-point-node-id%3D60%253A58%26show-proto-sidebar%3D1%26mode%3Ddesign",
    },
    description: [
      {
        descriptionTitle:
          "🌟 Designing Artyss: Elevating Commercial Spaces Through Exceptional UI/UX",
        descriptionContent:
          "As a UI/UX designer, I had the privilege to collaborate with client, Artyss, to craft an online presence that truly reflects their commitment to exceptional commercial interior design in Bangalore. My goal was to not only communicate their services but also to create a digital space that captures the essence of their brand.",
      },
      {
        descriptionTitle:
          "🖌️ Crafting a Seamless Digital Experience for Artyss: Redefining Interior Design Online",
        descriptionContent:
          "Artyss stands as a testament to professionalism and quality in commercial interior design. In designing their website, I aimed to echo these values by crafting an interface that is not only aesthetically pleasing but also highly functional. This ensures that visitors can effortlessly navigate the site, explore the diverse range of services, and gain insight into Artyss's unique approach.",
      },
    ],
    buttonsGroup: {
      View: false,
      Download: false,
      Buy: false,
    },
    socialShareUrl: "https://tzcreation.in/ui-ux-project/uiux26",
    tags: [
      { tag: "Commercial Interior Design" },
      { tag: "Website Design" },
      { tag: "User Experience" },
      { tag: "Brand Identity" },
      { tag: "UI/UX Design" },
    ],
  },
  uiux27: {
    id: "u27",
    pageName: "GRM Website Design",
    menuName: "Ui/Ux Projects",
    slug: "grm-website-design",
    ProjectLinks: {
      FigmaDesignLink:
        "https://www.figma.com/file/3npgTrAOpAlGxfITpOOUOs/GRM-Website-Design?type=design&node-id=2%3A2208&mode=design&t=tTC1tu15J1jFxuIK-1",
      FigmaDesignEmmbedLink:
        "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2F3npgTrAOpAlGxfITpOOUOs%2FGRM-Website-Design%3Ftype%3Ddesign%26node-id%3D2%253A2208%26mode%3Ddesign%26t%3DtTC1tu15J1jFxuIK-1",
      FigmaPrototypeLink:
        "https://www.figma.com/proto/3npgTrAOpAlGxfITpOOUOs/GRM-Website-Design?page-id=0%3A1&type=design&node-id=1-2&viewport=-217%2C192%2C0.13&t=PP7xszXohCnypOaf-1&scaling=scale-down-width&starting-point-node-id=1%3A2&show-proto-sidebar=1&mode=design",
      FigmaPrototypeEmbbedLink:
        "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2F3npgTrAOpAlGxfITpOOUOs%2FGRM-Website-Design%3Fpage-id%3D0%253A1%26type%3Ddesign%26node-id%3D1-2%26viewport%3D-217%252C192%252C0.13%26t%3DPP7xszXohCnypOaf-1%26scaling%3Dscale-down-width%26starting-point-node-id%3D1%253A2%26show-proto-sidebar%3D1%26mode%3Ddesign",
    },
    description: [
      {
        descriptionTitle:
          "🌟 Crafting a User-Centric Vision: GRM - Group of Education Website Design",
        descriptionContent:
          "As the sole UI/UX designer on this project, my mission was to transform the vision of GRM - Group of Education into a user-centric and visually compelling website. With a deep understanding of the problem statement discussed with the client, I crafted a comprehensive website that caters to the needs of students seeking to enroll in a diverse range of courses.",
      },
      {
        descriptionTitle:
          "🎓 Navigating Educational Journeys: Empowering Students through UX/UI Excellence",
        descriptionContent:
          "My website design not only simplifies the enrollment process but also serves as an informative hub, providing students with in-depth details about each course. Navigating through the platform, users can explore course offerings, access course materials, and find the resources needed to make informed educational choices. GRM's commitment to education and innovation is now reflected in a digital space that empowers students to shape their educational journeys.",
      },
    ],
    buttonsGroup: {
      View: false,
      Download: false,
      Buy: false,
    },
    socialShareUrl: "https://tzcreation.in/ui-ux-project/uiux27",
    tags: [
      { tag: "Educational Website" },
      { tag: "Student Enrollment" },
      { tag: "User-Centric Design" },
      { tag: "UI/UX Design" },
      { tag: "Figma UI/UX" },
    ],
  },
  uiux28: {
    id: "u28",
    pageName: "Saish H & F",
    menuName: "Ui/Ux Projects",
    slug: "saish-h-f",
    ProjectLinks: {
      FigmaDesignLink:
        "https://www.figma.com/file/YQov31t54lyAyoEvYdRczB/Saish-H-%26-F?type=design&node-id=0%3A1&mode=design&t=gebrGTcB7OaWw5LB-1",
      FigmaDesignEmmbedLink:
        "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FYQov31t54lyAyoEvYdRczB%2FSaish-H-%2526-F%3Ftype%3Ddesign%26node-id%3D0%253A1%26mode%3Ddesign%26t%3DgebrGTcB7OaWw5LB-1",
      FigmaPrototypeLink:
        "https://www.figma.com/proto/YQov31t54lyAyoEvYdRczB/Saish-H-%26-F?page-id=0%3A1&type=design&node-id=2-457&viewport=-1077%2C129%2C0.12&t=jM1Aa8pW18a2JFb9-1&scaling=scale-down-width&starting-point-node-id=2%3A457&mode=design",
      FigmaPrototypeEmbbedLink:
        "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FYQov31t54lyAyoEvYdRczB%2FSaish-H-%2526-F%3Fpage-id%3D0%253A1%26type%3Ddesign%26node-id%3D2-457%26viewport%3D-1077%252C129%252C0.12%26t%3DjM1Aa8pW18a2JFb9-1%26scaling%3Dscale-down-width%26starting-point-node-id%3D2%253A457%26mode%3Ddesign",
    },
    description: [
      {
        descriptionTitle:
          "🏋️‍♀️ Shaping Fitness through Design: Saish H & F's Online Presence",
        descriptionContent:
          "As a UI/UX designer, I had the privilege to collaborate with Saish H & F, a distinguished fitness and gym equipment selling company, to create an online platform that perfectly aligns with their vision. The goal was clear: design a website that embodies simplicity and elegance while showcasing their range of fitness products.",
      },
      {
        descriptionTitle:
          "💪 Elevating Fitness Retail: Crafting Saish H & F's User-Centric Website",
        descriptionContent:
          "At Saish H & F, fitness isn't just a business; it's a lifestyle. Therefore, I meticulously crafted variations of the website to meet the client's specific requests. The resulting digital presence not only provides a seamless shopping experience for customers but also reflects the company's commitment to quality and excellence in the fitness industry.",
      },
    ],
    buttonsGroup: {
      View: false,
      Download: false,
      Buy: false,
    },
    socialShareUrl: "https://tzcreation.in/ui-ux-project/uiux28",
    tags: [
      { tag: "Fitness Equipment" },
      { tag: "E-commerce Website" },
      { tag: "Simplicity" },
      { tag: "Elegance" },
      { tag: "UI/UX Design" },
    ],
  },
  uiux29: {
    id: "u29",
    pageName: "UpStreamLife",
    menuName: "Ui/Ux Projects",
    slug: "upstreamlife",
    ProjectLinks: {
      FigmaDesignLink:
        "https://www.figma.com/file/OvY18KpXOgWRu6wG3JkQIE/UpStreamLife?type=design&node-id=2%3A227&mode=design&t=etUiB8z9M1YuJB3g-1",
      FigmaDesignEmmbedLink:
        "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FOvY18KpXOgWRu6wG3JkQIE%2FUpStreamLife%3Ftype%3Ddesign%26node-id%3D2%253A227%26mode%3Ddesign%26t%3DetUiB8z9M1YuJB3g-1",
      FigmaPrototypeLink:
        "https://www.figma.com/proto/OvY18KpXOgWRu6wG3JkQIE/UpStreamLife?page-id=2%3A227&type=design&node-id=8-518&viewport=273%2C332%2C0.04&t=ig6vtkhriNkVvYwq-1&scaling=scale-down-width&starting-point-node-id=8%3A518&show-proto-sidebar=1&mode=design",
      FigmaPrototypeEmbbedLink:
        "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FOvY18KpXOgWRu6wG3JkQIE%2FUpStreamLife%3Fpage-id%3D2%253A227%26type%3Ddesign%26node-id%3D8-518%26viewport%3D273%252C332%252C0.04%26t%3Dig6vtkhriNkVvYwq-1%26scaling%3Dscale-down-width%26starting-point-node-id%3D8%253A518%26show-proto-sidebar%3D1%26mode%3Ddesign",
    },
    description: [
      {
        descriptionTitle:
          "🎙️ Crafting Success Stories: UpStreamLife's UI/UX Journey",
        descriptionContent:
          "As a UI/UX designer, I had the privilege to work on the UpStreamLife project—an exciting venture into the world of business podcasts. The goal was to create a digital platform where users could not only listen but also watch insightful interviews with successful entrepreneurs.",
      },
      {
        descriptionTitle:
          "🌟 Designing Knowledge Flow: Navigating Success with UpStreamLife",
        descriptionContent:
          "UpStreamLife isn't just a podcast; it's a journey through the stories of success. My role was to design an interface that seamlessly connects users with this wealth of knowledge. I focused on crafting an experience that is not only visually engaging but also intuitively navigable, ensuring that users can access these valuable insights with ease.",
      },
    ],
    buttonsGroup: {
      View: false,
      Download: false,
      Buy: false,
    },
    socialShareUrl: "https://tzcreation.in/ui-ux-project/uiux29",
    tags: [
      { tag: "Business Podcast" },
      { tag: "User Experience" },
      { tag: "Entrepreneurship" },
      { tag: "Podcast Website" },
      { tag: "UI/UX Design" },
    ],
  },
  uiux30: {
    id: "u30",
    pageName: "TeachersIndia UI Revamped",
    menuName: "Ui/Ux Projects",
    slug: "teachersindia-ui-revamped",
    ProjectLinks: {
      FigmaDesignLink:
        "https://www.figma.com/file/1maPgSagYx0XVlgfqfDRvf/TeachersIndia-UI-Revamped?type=design&node-id=0%3A1&mode=design&t=OU8eJG9J22ogmkuz-1",
      FigmaDesignEmmbedLink:
        "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2F1maPgSagYx0XVlgfqfDRvf%2FTeachersIndia-UI-Revamped%3Ftype%3Ddesign%26node-id%3D0%253A1%26mode%3Ddesign%26t%3DOU8eJG9J22ogmkuz-1",
      FigmaPrototypeLink:
        "https://www.figma.com/proto/1maPgSagYx0XVlgfqfDRvf/TeachersIndia-UI-Revamped?page-id=0%3A1&type=design&node-id=1-5&viewport=68%2C355%2C0.04&t=vUt4Lc5nJ1DQcvxb-1&scaling=scale-down-width&starting-point-node-id=1%3A5&show-proto-sidebar=1&mode=design",
      FigmaPrototypeEmbbedLink:
        "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2F1maPgSagYx0XVlgfqfDRvf%2FTeachersIndia-UI-Revamped%3Fpage-id%3D0%253A1%26type%3Ddesign%26node-id%3D1-5%26viewport%3D68%252C355%252C0.04%26t%3DvUt4Lc5nJ1DQcvxb-1%26scaling%3Dscale-down-width%26starting-point-node-id%3D1%253A5%26show-proto-sidebar%3D1%26mode%3Ddesign",
    },
    description: [
      {
        descriptionTitle:
          "🎓 Crafting Excellence: Redefining TeachersIndia Through UI/UX Revamp",
        descriptionContent:
          "In my role as a UI/UX designer, I had the privilege to embark on a transformative journey with TeachersIndia, one of the most esteemed teacher training institutes in the country. Our mission was clear: to revamp their digital interface, aligning it with their prestigious reputation and commitment to excellence.",
      },
      {
        descriptionTitle:
          "🌟 Navigating Educational Brilliance: TeachersIndia's Digital Transformation",
        descriptionContent:
          "TeachersIndia isn't just an institute; it's a beacon of quality education. With highly experienced and skillful faculties, they offer a level of training that's second to none. The challenge was to translate this excellence into a digital experience. Through thoughtful design, we aimed to provide an intuitive platform for students, offering access to a range of courses and programs. This revamp is more than a visual update; it's a testament to TeachersIndia's unwavering dedication to educational brilliance.",
      },
    ],
    buttonsGroup: {
      View: false,
      Download: false,
      Buy: false,
    },
    socialShareUrl: "https://tzcreation.in/ui-ux-project/uiux30",
    tags: [
      { tag: "Educational Excellence" },
      { tag: "Training Website" },
      { tag: "User Experience" },
      { tag: "Courses" },
      { tag: "Programs" },
    ],
  },
  uiux31: {
    id: "u31",
    pageName: "FMSCI Homepage Revamp",
    menuName: "Ui/Ux Projects",
    slug: "fmsci-homepage-revamp",
    ProjectLinks: {
      FigmaDesignLink:
        "https://www.figma.com/file/fSzjRKATh8CHeWe5qqvFs9/FMSCI-Homepage-Revamp?type=design&node-id=0%3A1&mode=design&t=4Pxxi1LxWI03hUPq-1",
      FigmaDesignEmmbedLink:
        "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FfSzjRKATh8CHeWe5qqvFs9%2FFMSCI-Homepage-Revamp%3Ftype%3Ddesign%26node-id%3D0%253A1%26mode%3Ddesign%26t%3D4Pxxi1LxWI03hUPq-1",
      FigmaPrototypeLink:
        "https://www.figma.com/proto/fSzjRKATh8CHeWe5qqvFs9/FMSCI-Homepage-Revamp?page-id=0%3A1&type=design&node-id=2-689&viewport=-286%2C645%2C0.1&t=0LnhJZ0LyYS88zxH-1&scaling=scale-down-width&starting-point-node-id=2%3A689&show-proto-sidebar=1&mode=design",
      FigmaPrototypeEmbbedLink:
        "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FfSzjRKATh8CHeWe5qqvFs9%2FFMSCI-Homepage-Revamp%3Fpage-id%3D0%253A1%26type%3Ddesign%26node-id%3D2-689%26viewport%3D-286%252C645%252C0.1%26t%3D0LnhJZ0LyYS88zxH-1%26scaling%3Dscale-down-width%26starting-point-node-id%3D2%253A689%26show-proto-sidebar%3D1%26mode%3Ddesign",
    },
    description: [
      {
        descriptionTitle:
          "🚗 Navigating Safety: FMSCI Homepage Revamp for Road Users",
        descriptionContent:
          "As a UI/UX designer, I had the honor of collaborating on the FMSCI homepage revamp project—a crucial initiative by the FIA to enhance road safety for everyday users worldwide. The goal was clear: to transform their digital presence into an informative hub, offering events, news, regulations, calendars, and much more.",
      },
    ],
    buttonsGroup: {
      View: false,
      Download: false,
      Buy: false,
    },
    socialShareUrl: "https://tzcreation.in/ui-ux-project/uiux31",
    tags: [
      { tag: "Homepage Revamp" },
      { tag: "Road Safety" },
      { tag: "Information Hub" },
      { tag: "User Experience" },
      { tag: "UI/UX Design" },
    ],
  },
  uiux32: {
    id: "u32",
    pageName: "Meco Web UiUx Design",
    menuName: "Ui/Ux Projects",
    slug: "uiux-project-slug",
    ProjectLinks: {
      FigmaDesignLink:
        "https://www.figma.com/file/cyMUEkKW5vhO5rrzg9Y5Yb/Meco-Web-UiUx-Design-by-Tabrez-Dal?type=design&node-id=0%3A1&mode=design&t=MzmLj0OWleU25A46-1",
      FigmaDesignEmmbedLink:
        "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FcyMUEkKW5vhO5rrzg9Y5Yb%2FMeco-Web-UiUx-Design-by-Tabrez-Dal%3Ftype%3Ddesign%26node-id%3D0%253A1%26mode%3Ddesign%26t%3DMzmLj0OWleU25A46-1",
      FigmaPrototypeLink:
        "https://www.figma.com/proto/cyMUEkKW5vhO5rrzg9Y5Yb/Meco-Web-UiUx-Design-by-Tabrez-Dal?page-id=0%3A1&type=design&node-id=53-1523&viewport=-135%2C222%2C0.02&t=iPuSv8HyDtJZYz82-1&scaling=scale-down&starting-point-node-id=53%3A1523&show-proto-sidebar=1&mode=design",
      FigmaPrototypeEmbbedLink:
        "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FcyMUEkKW5vhO5rrzg9Y5Yb%2FMeco-Web-UiUx-Design-by-Tabrez-Dal%3Fpage-id%3D0%253A1%26type%3Ddesign%26node-id%3D53-1523%26viewport%3D-135%252C222%252C0.02%26t%3DiPuSv8HyDtJZYz82-1%26scaling%3Dscale-down%26starting-point-node-id%3D53%253A1523%26show-proto-sidebar%3D1%26mode%3Ddesign",
    },
    description: [
      {
        descriptionTitle:
          "🏁 MECO: Driving Motorsports Excellence through UI/UX Innovation",
        descriptionContent:
          "As a UI/UX designer, I had the privilege of collaborating with MECO, India's premier motorsports management enterprise. MECO has emerged as one of the biggest names in Indian motorsports, owning and managing premier racing tracks across the country and organizing top racing events and championships.",
      },
      {
        descriptionTitle: "",
        descriptionContent:
          "My mission was to encapsulate MECO's dynamic and multi-faceted approach to motorsports in a comprehensive website. The challenge lay in presenting a multitude of ideas and concepts, aligning with a team that held a relentless drive towards the excitement of racing and podium finishes. Through intuitive design, we aimed to convey MECO's commitment to the consolidation, expansion, and international exposure of motorsports in India.",
      },
    ],
    buttonsGroup: {
      View: false,
      Download: false,
      Buy: false,
    },
    socialShareUrl: "https://tzcreation.in/ui-ux-project/uiux32",
    tags: [
      { tag: "Motorsports" },
      { tag: "Racing Events" },
      { tag: "Racing Website" },
      { tag: "User Experience" },
      { tag: "UI/UX Design" },
    ],
  },
  uiux33: {
    id: "u33",
    pageName: "Meco Web UiUx Final Design",
    menuName: "Ui/Ux Projects",
    slug: "uiux-project-slug",
    ProjectLinks: {
      FigmaDesignLink:
        "https://www.figma.com/file/RhxolZEwe1bjcxfIXgnc7A/Meco-Web-UiUx-Final-Design-by-Tabrez-Dal?type=design&node-id=0%3A1&mode=design&t=0uXfBHcLc4zqsbK3-1",
      FigmaDesignEmmbedLink:
        "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FRhxolZEwe1bjcxfIXgnc7A%2FMeco-Web-UiUx-Final-Design-by-Tabrez-Dal%3Ftype%3Ddesign%26node-id%3D0%253A1%26mode%3Ddesign%26t%3D0uXfBHcLc4zqsbK3-1",
      FigmaPrototypeLink:
        "https://www.figma.com/proto/RhxolZEwe1bjcxfIXgnc7A/Meco-Web-UiUx-Final-Design-by-Tabrez-Dal?page-id=0%3A1&type=design&node-id=1202-20235&viewport=-255%2C255%2C0.02&t=BDmshOkRvgkuCVh0-1&scaling=scale-down&starting-point-node-id=1202%3A20235&show-proto-sidebar=1&mode=design",
      FigmaPrototypeEmbbedLink:
        "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FRhxolZEwe1bjcxfIXgnc7A%2FMeco-Web-UiUx-Final-Design-by-Tabrez-Dal%3Fpage-id%3D0%253A1%26type%3Ddesign%26node-id%3D1202-20235%26viewport%3D-255%252C255%252C0.02%26t%3DBDmshOkRvgkuCVh0-1%26scaling%3Dscale-down%26starting-point-node-id%3D1202%253A20235%26show-proto-sidebar%3D1%26mode%3Ddesign",
    },
    description: [
      {
        descriptionTitle:
          "🏁 MECO: Driving Motorsports Excellence through UI/UX Innovation",
        descriptionContent:
          "As a UI/UX designer, I had the privilege of collaborating with MECO, India's premier motorsports management enterprise. MECO has emerged as one of the biggest names in Indian motorsports, owning and managing premier racing tracks across the country and organizing top racing events and championships.",
      },
      {
        descriptionTitle: "",
        descriptionContent:
          "My mission was to encapsulate MECO's dynamic and multi-faceted approach to motorsports in a comprehensive website. The challenge lay in presenting a multitude of ideas and concepts, aligning with a team that held a relentless drive towards the excitement of racing and podium finishes. Through intuitive design, we aimed to convey MECO's commitment to the consolidation, expansion, and international exposure of motorsports in India.",
      },
    ],
    buttonsGroup: {
      View: false,
      Download: false,
      Buy: false,
    },
    socialShareUrl: "https://tzcreation.in/ui-ux-project/uiux33",
    tags: [
      { tag: "Motorsports" },
      { tag: "Racing Events" },
      { tag: "Racing Website" },
      { tag: "User Experience" },
      { tag: "UI/UX Design" },
    ],
  },
  uiux34: {
    id: "u34",
    pageName: "EdXplore Admin Panel",
    menuName: "Ui/Ux Projects",
    slug: "edxplore-admin-panel",
    ProjectLinks: {
      FigmaDesignLink:
        "https://www.figma.com/file/SlofBkzRyGfy7lMBB0CaQc/EdXplore-Admin-Panel?type=design&node-id=0%3A1&mode=design&t=vqoFr3c4I7uqGycb-1",
      FigmaDesignEmmbedLink:
        "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FSlofBkzRyGfy7lMBB0CaQc%2FEdXplore-Admin-Panel%3Ftype%3Ddesign%26node-id%3D0%253A1%26mode%3Ddesign%26t%3DvqoFr3c4I7uqGycb-1",
      FigmaPrototypeLink:
        "https://www.figma.com/proto/SlofBkzRyGfy7lMBB0CaQc/EdXplore-Admin-Panel?page-id=0%3A1&type=design&node-id=2270-33461&viewport=273%2C56%2C0.02&t=Sh01jIKrnMfIjrVm-1&scaling=min-zoom&starting-point-node-id=2270%3A33461&show-proto-sidebar=1&mode=design",
      FigmaPrototypeEmbbedLink:
        "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FSlofBkzRyGfy7lMBB0CaQc%2FEdXplore-Admin-Panel%3Fpage-id%3D0%253A1%26type%3Ddesign%26node-id%3D2270-33461%26viewport%3D273%252C56%252C0.02%26t%3DSh01jIKrnMfIjrVm-1%26scaling%3Dmin-zoom%26starting-point-node-id%3D2270%253A33461%26show-proto-sidebar%3D1%26mode%3Ddesign",
    },
    description: [
      {
        descriptionTitle:
          "🚀 EdXplore Admin Panel: Navigating Skill Enhancement through UI/UX Excellence",
        descriptionContent:
          "As a UI/UX designer, I had the privilege of working on the EdXplore Admin Panel, a vital component of India's leading skilling platform. This project aimed to streamline the user interface for administrators, ensuring they could efficiently manage the platform's diverse offerings, from verified training institutes to trending courses.",
      },
      {
        descriptionTitle: "",
        descriptionContent:
          "The EdXplore Admin Panel isn't just an interface; it's the control center for skill enhancement. My mission was to create an intuitive and user-friendly design that empowers administrators to easily oversee and optimize the platform's operations. This project was about more than just design; it was about providing the tools needed to facilitate skill development on a national scale.",
      },
    ],
    buttonsGroup: {
      View: false,
      Download: false,
      Buy: false,
    },
    socialShareUrl: "https://tzcreation.in/ui-ux-project/uiux34",
    tags: [
      { tag: "Admin Panel" },
      { tag: "Admin Panel Design" },
      { tag: "Training" },
      { tag: "Institutes" },
      { tag: "Education" },
    ],
  },
  uiux35: {
    id: "u35",
    pageName: "EdXplore Admin Panel [Mobile Version]",
    menuName: "Ui/Ux Projects",
    slug: "edxplore-admin-panel-mobile-version",
    ProjectLinks: {
      FigmaDesignLink:
        "https://www.figma.com/file/ofBvY7bsfgUJG2lKyjFrYU/EdXplore-Admin-Panel-%5BMobile-Version%5D?type=design&node-id=1054%3A27194&mode=design&t=HQDz2ME6icyBv64q-1",
      FigmaDesignEmmbedLink:
        "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FofBvY7bsfgUJG2lKyjFrYU%2FEdXplore-Admin-Panel-%255BMobile-Version%255D%3Ftype%3Ddesign%26node-id%3D1054%253A27194%26mode%3Ddesign%26t%3DHQDz2ME6icyBv64q-1",
      FigmaPrototypeLink:
        "https://www.figma.com/proto/ofBvY7bsfgUJG2lKyjFrYU/EdXplore-Admin-Panel-%5BMobile-Version%5D?page-id=1054%3A27194&type=design&node-id=2-6768&viewport=-359%2C-34%2C0.16&t=nQCNoc5IfIBWwhQ1-1&scaling=min-zoom&starting-point-node-id=2%3A6768&show-proto-sidebar=1&mode=design",
      FigmaPrototypeEmbbedLink:
        "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FofBvY7bsfgUJG2lKyjFrYU%2FEdXplore-Admin-Panel-%255BMobile-Version%255D%3Fpage-id%3D1054%253A27194%26type%3Ddesign%26node-id%3D2-6768%26viewport%3D-359%252C-34%252C0.16%26t%3DnQCNoc5IfIBWwhQ1-1%26scaling%3Dmin-zoom%26starting-point-node-id%3D2%253A6768%26show-proto-sidebar%3D1%26mode%3Ddesign",
    },
    description: [
      {
        descriptionTitle:
          "📱 EdXplore Admin Panel [Mobile Version]: Mobilizing Skill Development through UI/UX Mastery",
        descriptionContent:
          "In my role as a UI/UX designer, I embarked on the journey of creating the EdXplore Admin Panel [Mobile Version], an essential facet of India's leading skilling platform. This project aimed to provide administrators with a mobile-friendly interface, ensuring they could efficiently manage the platform's diverse offerings, from training institutes to trending courses, while on the go.",
      },
      {
        descriptionTitle: "",
        descriptionContent:
          "The EdXplore Admin Panel [Mobile Version] is about accessibility and convenience. My goal was to design an interface that allows administrators to seamlessly oversee and optimize the platform from the palm of their hand. This project wasn't just about design; it was about empowering administrators to mobilize skill development across India.",
      },
    ],
    buttonsGroup: {
      View: false,
      Download: false,
      Buy: false,
    },
    socialShareUrl: "https://tzcreation.in/ui-ux-project/uiux35",
    tags: [
      { tag: "Admin Panel" },
      { tag: "Admin Panel Design" },
      { tag: "Mobile Ui Design" },
      { tag: "Institutes" },
      { tag: "Education" },
    ],
  },
  uiux36: {
    id: "u36",
    pageName: "EdXplore Header Revamp",
    menuName: "Ui/Ux Projects",
    slug: "edxplore-header-revamp",
    ProjectLinks: {
      FigmaDesignLink:
        "https://www.figma.com/file/HnvvMCCbBKIWPOV2PrTdsy/EdXplore-Header-Revamp?type=design&node-id=123%3A1367&mode=design&t=S9ikM8RY1uxcaUJa-1",
      FigmaDesignEmmbedLink:
        "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FHnvvMCCbBKIWPOV2PrTdsy%2FEdXplore-Header-Revamp%3Ftype%3Ddesign%26node-id%3D123%253A1367%26mode%3Ddesign%26t%3DS9ikM8RY1uxcaUJa-1",
      FigmaPrototypeLink:
        "https://www.figma.com/proto/HnvvMCCbBKIWPOV2PrTdsy/EdXplore-Header-Revamp?page-id=123%3A1367&type=design&node-id=137-1368&viewport=403%2C378%2C0.04&t=Qv0HY1JkdAJ1MRAP-1&scaling=scale-down&mode=design",
      FigmaPrototypeEmbbedLink:
        "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FHnvvMCCbBKIWPOV2PrTdsy%2FEdXplore-Header-Revamp%3Fpage-id%3D123%253A1367%26type%3Ddesign%26node-id%3D137-1368%26viewport%3D403%252C378%252C0.04%26t%3DQv0HY1JkdAJ1MRAP-1%26scaling%3Dscale-down%26mode%3Ddesign",
    },
    description: [
      {
        descriptionTitle:
          "🔍 EdXplore Header Revamp: Enhancing User Experience through UI/UX Innovation",
        descriptionContent:
          "As a UI/UX designer, I took on the challenge of revamping the EdXplore website's header. The client sought a unique concept to enhance the header's functionality and user accessibility. The goal was to create a design that not only highlights the search feature but also ensures a seamless and user-friendly experience.",
      },
      {
        descriptionTitle: "",
        descriptionContent:
          "The EdXplore Header Revamp is all about making information more accessible. My mission was to reimagine the header's design in a way that simplifies the user journey and enhances the platform's ease of use. This project was a testament to the importance of user-centered design in elevating the online learning experience.",
      },
    ],
    buttonsGroup: {
      View: false,
      Download: false,
      Buy: false,
    },
    socialShareUrl: "https://tzcreation.in/ui-ux-project/uiux36",
    tags: [
      { tag: "Header Revamp" },
      { tag: "Revamp Design" },
      { tag: "Website Header Design" },
      { tag: "Institutes" },
      { tag: "Education" },
    ],
  },
  uiux37: {
    id: "u37",
    pageName: "SkillMine [UK]",
    menuName: "Ui/Ux Projects",
    slug: "skillmine-uk",
    ProjectLinks: {
      FigmaDesignLink:
        "https://www.figma.com/file/ShlQvDHCX99zbYWlSDV2zH/SkillMine-%5BUK%5D?type=design&node-id=124%3A12438&mode=design&t=MOLtkMu4Vpsz33sj-1",
      FigmaDesignEmmbedLink:
        "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FShlQvDHCX99zbYWlSDV2zH%2FSkillMine-%255BUK%255D%3Ftype%3Ddesign%26node-id%3D124%253A12438%26mode%3Ddesign%26t%3DMOLtkMu4Vpsz33sj-1",
      FigmaPrototypeLink:
        "https://www.figma.com/proto/ShlQvDHCX99zbYWlSDV2zH/SkillMine-%5BUK%5D?page-id=124%3A12438&type=design&node-id=124-12467&viewport=196%2C343%2C0.02&t=7IxNU3qgYhGD4K7o-1&scaling=scale-down&starting-point-node-id=124%3A12467&show-proto-sidebar=1&mode=design",
      FigmaPrototypeEmbbedLink:
        "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FShlQvDHCX99zbYWlSDV2zH%2FSkillMine-%255BUK%255D%3Fpage-id%3D124%253A12438%26type%3Ddesign%26node-id%3D124-12467%26viewport%3D196%252C343%252C0.02%26t%3D7IxNU3qgYhGD4K7o-1%26scaling%3Dscale-down%26starting-point-node-id%3D124%253A12467%26show-proto-sidebar%3D1%26mode%3Ddesign",
    },
    description: [
      {
        descriptionTitle:
          "SkillMine [UK]: Pioneering IT Transformation Through UI/UX Excellence",
        descriptionContent:
          "In my capacity as a UI/UX designer, I embarked on a transformative journey with SkillMine [UK], a prominent IT service provider in the United Kingdom. The objective was clear: to create a digital presence that showcases their extensive IT service offerings, including digital transformation, cybersecurity, cloud infrastructure, and more.",
      },
      {
        descriptionTitle: "",
        descriptionContent:
          "SkillMine [UK] isn't just a company; it's an architect of technological progress. My role was to design a user-friendly and visually engaging website that aligns with their commitment to innovation and excellence. This project was about more than just design; it was about crafting a digital space that mirrors SkillMine [UK]'s role in redefining the IT landscape in the United Kingdom.",
      },
    ],
    buttonsGroup: {
      View: false,
      Download: false,
      Buy: false,
    },
    socialShareUrl: "https://tzcreation.in/ui-ux-project/uiux37",
    tags: [
      { tag: "IT Services" },
      { tag: "IT Company" },
      { tag: "Full Fledge Website" },
      { tag: "User Experience" },
      { tag: "United Kingdom" },
    ],
  },
  uiux38: {
    id: "u38",
    pageName: "SkillMine [India]",
    menuName: "Ui/Ux Projects",
    slug: "skillmine-india",
    ProjectLinks: {
      FigmaDesignLink:
        "https://www.figma.com/file/yPs7QfEvl19V3ztD0e0UEr/SkillMine-%5BIndia%5D?type=design&node-id=0%3A1&mode=design&t=IrXsFLOZk8IgXP5w-1",
      FigmaDesignEmmbedLink:
        "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FyPs7QfEvl19V3ztD0e0UEr%2FSkillMine-%255BIndia%255D%3Ftype%3Ddesign%26node-id%3D0%253A1%26mode%3Ddesign%26t%3DIrXsFLOZk8IgXP5w-1",
      FigmaPrototypeLink:
        "https://www.figma.com/proto/yPs7QfEvl19V3ztD0e0UEr/SkillMine-%5BIndia%5D?page-id=0%3A1&type=design&node-id=302-2195&viewport=283%2C164%2C0.02&t=7MI1M0RRZ0h7lrfE-1&scaling=scale-down-width&starting-point-node-id=302%3A2195&show-proto-sidebar=1&mode=design",
      FigmaPrototypeEmbbedLink:
        "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FyPs7QfEvl19V3ztD0e0UEr%2FSkillMine-%255BIndia%255D%3Fpage-id%3D0%253A1%26type%3Ddesign%26node-id%3D302-2195%26viewport%3D283%252C164%252C0.02%26t%3D7MI1M0RRZ0h7lrfE-1%26scaling%3Dscale-down-width%26starting-point-node-id%3D302%253A2195%26show-proto-sidebar%3D1%26mode%3Ddesign",
    },
    description: [
      {
        descriptionTitle: "descriptionTitle1",
        descriptionContent:
          "As a UI/UX designer, I had the privilege to work on SkillMine [India], a leading IT service provider dedicated to driving digital transformation. This project aimed to showcase their extensive range of services, including digital transformation, cloud infrastructure, cybersecurity, app development, and more. Our goal was to create a digital platform that not only informs but also reflects SkillMine's commitment to excellence in the IT sector.",
      },
      {
        descriptionTitle: "",
        descriptionContent:
          "SkillMine [India] isn't just a company; it's a catalyst for innovation. My role was to ensure that the website design resonates with this spirit. Through user-friendly and visually appealing design, we aimed to provide visitors with easy access to information about the broad spectrum of services they offer. This project was about more than just design; it was about creating a digital experience that mirrors SkillMine's role in transforming the IT landscape in India.",
      },
    ],
    buttonsGroup: {
      View: false,
      Download: false,
      Buy: false,
    },
    socialShareUrl: "https://tzcreation.in/ui-ux-project/uiux38",
    tags: [
      { tag: "IT Services" },
      { tag: "IT Company" },
      { tag: "Full Fledge Website" },
      { tag: "User Experience" },
      { tag: "UI/UX Design" },
    ],
  },
  uiux39: {
    id: "u39",
    pageName: "SkillMine [KSA]",
    menuName: "Ui/Ux Projects",
    slug: "skillmine-ksa",
    ProjectLinks: {
      FigmaDesignLink:
        "https://www.figma.com/file/IR4Frc7niFlrrDWLJINipU/SkillMine-%5BKSA%5D?type=design&node-id=64%3A2623&mode=design&t=rjGyPm2mPBkSAIyM-1",
      FigmaDesignEmmbedLink:
        "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FIR4Frc7niFlrrDWLJINipU%2FSkillMine-%255BKSA%255D%3Ftype%3Ddesign%26node-id%3D64%253A2623%26mode%3Ddesign%26t%3DrjGyPm2mPBkSAIyM-1",
      FigmaPrototypeLink:
        "https://www.figma.com/proto/IR4Frc7niFlrrDWLJINipU/SkillMine-%5BKSA%5D?page-id=64%3A2623&type=design&node-id=123-5198&viewport=194%2C152%2C0.02&t=6yfqJydJSCuJdiG7-1&scaling=scale-down-width&starting-point-node-id=123%3A5198&show-proto-sidebar=1&mode=design",
      FigmaPrototypeEmbbedLink:
        "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FIR4Frc7niFlrrDWLJINipU%2FSkillMine-%255BKSA%255D%3Fpage-id%3D64%253A2623%26type%3Ddesign%26node-id%3D123-5198%26viewport%3D194%252C152%252C0.02%26t%3D6yfqJydJSCuJdiG7-1%26scaling%3Dscale-down-width%26starting-point-node-id%3D123%253A5198%26show-proto-sidebar%3D1%26mode%3Ddesign",
    },
    description: [
      {
        descriptionTitle:
          "SkillMine [KSA]: Forging IT Excellence Through UI/UX Mastery",
        descriptionContent:
          "As a UI/UX designer, I was honored to collaborate with SkillMine [KSA], a prominent IT service provider in Saudi Arabia, to craft a digital platform that showcases their extensive array of services, including digital transformation, cloud infrastructure, cybersecurity, and more.",
      },
      {
        descriptionTitle: "",
        descriptionContent:
          "SkillMine [KSA] isn't just a company; it's an enabler of technological progress. My role was to create a visually appealing and user-friendly website that aligns with their commitment to innovation and excellence. This project was about more than just design; it was about crafting a digital space that reflects SkillMine [KSA]'s pivotal role in shaping IT excellence in Saudi Arabia.",
      },
    ],
    buttonsGroup: {
      View: false,
      Download: false,
      Buy: false,
    },
    socialShareUrl: "https://tzcreation.in/ui-ux-project/uiux39",
    tags: [
      { tag: "IT Services" },
      { tag: "IT Company" },
      { tag: "Full Fledge Website" },
      { tag: "User Experience" },
      { tag: "Kingdom of Saudi Arabia" },
    ],
  },
  uiux40: {
    id: "u40",
    pageName: "SkillMine Auth",
    menuName: "Ui/Ux Projects",
    slug: "skillmine-auth",
    ProjectLinks: {
      FigmaDesignLink:
        "https://www.figma.com/file/qW3ikxRNbGPZ9vYuldfTJt/SkillMine-Auth?type=design&node-id=233%3A4671&mode=design&t=pHlNYFh94hjOn7um-1",
      FigmaDesignEmmbedLink:
        "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FqW3ikxRNbGPZ9vYuldfTJt%2FSkillMine-Auth%3Ftype%3Ddesign%26node-id%3D233%253A4671%26mode%3Ddesign%26t%3DpHlNYFh94hjOn7um-1",
      FigmaPrototypeLink:
        "https://www.figma.com/proto/qW3ikxRNbGPZ9vYuldfTJt/SkillMine-Auth?page-id=249%3A11636&type=design&node-id=249-13362&viewport=378%2C203%2C0.07&t=XlNnRYyEGqyvP5Cy-1&scaling=min-zoom&starting-point-node-id=249%3A13362&show-proto-sidebar=1",
      FigmaPrototypeEmbbedLink:
        "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FqW3ikxRNbGPZ9vYuldfTJt%2FSkillMine-Auth%3Fpage-id%3D249%253A11636%26type%3Ddesign%26node-id%3D249-11637%26viewport%3D378%252C203%252C0.07%26t%3DXlNnRYyEGqyvP5Cy-1%26scaling%3Dscale-down-width%26starting-point-node-id%3D249%253A13362%26show-proto-sidebar%3D1%26mode%3Ddesign",
    },
    description: [
      {
        descriptionTitle:
          "🔒 SkillMine Auth: Redefining Digital Authentication through UI/UX Innovation",
        descriptionContent:
          "SkillMine Auth, a product of SkillMine, represents a revolutionary leap in the field of digital authentication. This unified authenticator platform supports all major protocols and seamlessly integrates with enterprise applications, partner applications, and social media platforms without the need for any modifications.",
      },
      {
        descriptionTitle: "",
        descriptionContent:
          "As a UI/UX designer, my task was to create an interface that communicates the power and versatility of SkillMine Auth. Through intuitive design, I aimed to showcase how this product simplifies authentication while maintaining robust security. SkillMine Auth isn't just a product; it's a solution that redefines digital authentication for the modern world.",
      },
    ],
    buttonsGroup: {
      View: false,
      Download: false,
      Buy: false,
    },
    socialShareUrl: "https://tzcreation.in/ui-ux-project/uiux39",
    tags: [
      { tag: "Unified Authenticator Platform" },
      { tag: "Digital Security" },
      { tag: "Authentication" },
      { tag: "User Experience" },
      { tag: "UI/UX Design" },
    ],
  },
  uiux41: {
    id: "u41",
    pageName: "Hotel & Resort Management",
    menuName: "Ui/Ux Projects",
    slug: "hotel-and-resort-management",
    ProjectLinks: {
      FigmaDesignLink:
        "https://www.figma.com/file/mnhx8mn78DZByk1jIVfdG9/Hotel-%26-Resort-Management?type=design&node-id=2%3A2&mode=design&t=GwoNfQcYYcekQc6j-1",
      FigmaDesignEmmbedLink:
        "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2Fmnhx8mn78DZByk1jIVfdG9%2FHotel-%2526-Resort-Management%3Ftype%3Ddesign%26node-id%3D2%253A2%26mode%3Ddesign%26t%3DGwoNfQcYYcekQc6j-1",
      FigmaPrototypeLink:
        "https://www.figma.com/proto/mnhx8mn78DZByk1jIVfdG9/Hotel-%26-Resort-Management?page-id=2%3A2&type=design&node-id=111-16835&viewport=-2%2C438%2C0.02&t=9Q5wIViltWbA3dCi-1&scaling=scale-down-width&starting-point-node-id=111%3A16835&show-proto-sidebar=1&mode=design",
      FigmaPrototypeEmbbedLink:
        "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2Fmnhx8mn78DZByk1jIVfdG9%2FHotel-%2526-Resort-Management%3Fpage-id%3D2%253A2%26type%3Ddesign%26node-id%3D111-16835%26viewport%3D-2%252C438%252C0.02%26t%3D9Q5wIViltWbA3dCi-1%26scaling%3Dscale-down-width%26starting-point-node-id%3D111%253A16835%26show-proto-sidebar%3D1%26mode%3Ddesign",
    },
    description: [
      {
        descriptionTitle:
          "🌟 Designing Getaways: Elevating Outings with Hotel & Resort Management",
        descriptionContent:
          "In my role as a UI/UX designer, I embarked on a journey with the Hotel & Resort Management project—an endeavor to create a digital destination that caters to all aspects of a perfect outing. This one-stop platform seamlessly combines hotel bookings, resort stays, villa accommodations, nearby activities, food ordering from booked hotels, and more.",
      },
      {
        descriptionTitle:
          "🏨 A Digital Escape: Creating Leisure Experiences Through UI/UX Excellence",
        descriptionContent:
          "Hotel & Resort Management isn't just a website; it's an experience, a gateway to unforgettable outings. My design approach focused on simplicity and elegance, ensuring that the digital interface matches the leisurely and refined experiences it offers. This project aimed to provide users with a visually pleasing, user-friendly platform where they can plan and enhance their leisure activities effortlessly.",
      },
    ],
    buttonsGroup: {
      View: false,
      Download: false,
      Buy: false,
    },
    socialShareUrl: "https://tzcreation.in/ui-ux-project/uiux41",
    tags: [
      { tag: "Hotel Booking" },
      { tag: "Leisure Management" },
      { tag: "Resort Stays" },
      { tag: "Villa Accommodations" },
      { tag: "User Experience" },
    ],
  },
  // uiux42: {
  //   id: "u42",
  //   pageName: "ZeroZilla Profile",
  //   menuName: "Ui/Ux Projects",
  //   slug: "zerozilla-profile",
  //   ProjectLinks: {
  //     FigmaDesignLink:
  //       "https://www.figma.com/file/OvY18KpXOgWRu6wG3JkQIE/UpStreamLife?type=design&node-id=2%3A227&mode=design&t=etUiB8z9M1YuJB3g-1",
  //     FigmaDesignEmmbedLink:
  //       "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FJSc6X86Vw3QJZjgOzKFUWz%2FZeroZilla-Profile%3Ftype%3Ddesign%26node-id%3D28%253A1140%26mode%3Ddesign%26t%3DvpyUMc11OHMIv0m5-1",
  //     FigmaPrototypeLink:
  //       "https://www.figma.com/proto/JSc6X86Vw3QJZjgOzKFUWz/ZeroZilla-Profile?page-id=0%3A1&type=design&node-id=1-32&viewport=311%2C315%2C0.17&t=I3Gk5bF61FDREizV-1&scaling=scale-down&mode=design",
  //     FigmaPrototypeEmbbedLink:
  //       "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FJSc6X86Vw3QJZjgOzKFUWz%2FZeroZilla-Profile%3Fpage-id%3D0%253A1%26type%3Ddesign%26node-id%3D1-32%26viewport%3D311%252C315%252C0.17%26t%3DI3Gk5bF61FDREizV-1%26scaling%3Dscale-down%26mode%3Ddesign",
  //   },
  //   description: [
  //     {
  //       descriptionTitle:
  //         "📈 Redefining Identity: ZeroZilla Profile Correction through Design",
  //       descriptionContent:
  //         "As a UI/UX designer, I embarked on a mission to redefine ZeroZilla's online profile. My role was to make necessary corrections, aligning their digital presence with their corporate identity and vision.",
  //     },
  //   ],
  //   buttonsGroup: {
  //     View: false,
  //     Download: false,
  //     Buy: false,
  //   },
  //   socialShareUrl: "https://tzcreation.in/ui-ux-project/uiux42",
  //   tags: [
  //     { tag: "Digital Profile" },
  //     { tag: "Corporate Identity" },
  //     { tag: "Branding" },
  //     { tag: "User Experience" },
  //     { tag: "UI/UX Design" },
  //   ],
  // },
};

export default UiUxProjectPageData;
