import ImageHelper from "../../Helpers/ImageHelper";
import UiUxProjectPageData from "../UiUxPageData/UiUxProjectPageData";

const PortfolioDataUiUx = [
  {
    title: "Bidroid Old Ui",
    caption: "App Ui",
    image: ImageHelper.BidroidOldUI,
    fullWebSS: false,
    hasInnerPage: false,
    urlSuffix: "uiux01",
    innerPageData: UiUxProjectPageData.uiux01,
  },
  {
    title: "Bidroid New Ui",
    caption: "App Ui",
    image: ImageHelper.BidroidNewUI,
    fullWebSS: false,
    hasInnerPage: false,
    urlSuffix: "uiux02",
    innerPageData: UiUxProjectPageData.uiux02,
  },
  {
    title: "CricBuzz App Ui",
    caption: "App Ui",
    image: ImageHelper.CricBuzzUI,
    fullWebSS: false,
    hasInnerPage: false,
    urlSuffix: "uiux03",
    innerPageData: UiUxProjectPageData.uiux03,
  },
  {
    title: "Soy Hill Candle Co.",
    caption: "Prototype",
    image: ImageHelper.SoyHill1,
    fullWebSS: true,
    hasInnerPage: false,
    urlSuffix: "uiux04",
    innerPageData: UiUxProjectPageData.uiux04,
  },
  {
    title: "Soy Hill Candle Co.",
    caption: "Prototype",
    image: ImageHelper.SoyHill2,
    fullWebSS: true,
    hasInnerPage: false,
    urlSuffix: "uiux05",
    innerPageData: UiUxProjectPageData.uiux05,
  },
  {
    title: "Soy Hill Candle Co.",
    caption: "Prototype",
    image: ImageHelper.SoyHill3,
    fullWebSS: true,
    hasInnerPage: false,
    urlSuffix: "uiux06",
    innerPageData: UiUxProjectPageData.uiux06,
  },
  {
    title: "Bidroid App",
    caption: "UiUx Design",
    image: ImageHelper.BidroidAppBanner,
    fullWebSS: false,
    hasInnerPage: true,
    urlSuffix: "uiux07",
    innerPageData: UiUxProjectPageData.uiux07,
  },
  {
    title: "Tabrez Dal - Expert Product Designer",
    caption: "Design",
    image: ImageHelper.TabrezDalExpertProductDesignerCover,
    fullWebSS: false,
    hasInnerPage: true,
    urlSuffix: "uiux08",
    innerPageData: UiUxProjectPageData.uiux08,
  },
  {
    title: "TezHub",
    caption: "UiUx Design",
    image: ImageHelper.TezHUBCover,
    fullWebSS: false,
    hasInnerPage: true,
    urlSuffix: "uiux09",
    innerPageData: UiUxProjectPageData.uiux09,
  },
  // {
  //   title: "Food Delivery App",
  //   caption: "UiUx Design",
  //   image: ImageHelper.EatExpress,
  //   fullWebSS: false,
  //   hasInnerPage: true,
  //   urlSuffix: "uiux10",
  //   innerPageData: UiUxProjectPageData.uiux10,
  // },
  {
    title: "CasualChic- Casual Wear Store",
    caption: "UiUx Design",
    image: ImageHelper.CasualChic,
    fullWebSS: false,
    hasInnerPage: true,
    urlSuffix: "uiux10",
    innerPageData: UiUxProjectPageData.uiux10,
  },
  {
    title: "PropertyZone Landing Page",
    caption: "UiUx Design",
    image: ImageHelper.PropertyZoneLandingPageCover,
    fullWebSS: false,
    hasInnerPage: true,
    urlSuffix: "uiux11",
    innerPageData: UiUxProjectPageData.uiux11,
  },
  {
    title: "King Graphics Homepage",
    caption: "UiUx Design",
    image: ImageHelper.KingGraphicsTask4,
    fullWebSS: false,
    hasInnerPage: true,
    urlSuffix: "uiux12",
    innerPageData: UiUxProjectPageData.uiux12,
  },
  {
    title: "MyHabit App Ui Design",
    caption: "UiUx Design",
    image: ImageHelper.MyHabitAppUiDesignCover,
    fullWebSS: false,
    hasInnerPage: true,
    urlSuffix: "uiux13",
    innerPageData: UiUxProjectPageData.uiux13,
  },
  {
    title: "LinkedUp",
    caption: "UiUx Design",
    image: ImageHelper.LinkedupAppUiDesign,
    fullWebSS: false,
    hasInnerPage: true,
    urlSuffix: "uiux14",
    innerPageData: UiUxProjectPageData.uiux14,
  },
  {
    title: "Rush Invoice App",
    caption: "UiUx Design",
    image: ImageHelper.RushInvoiceAppCover,
    fullWebSS: false,
    hasInnerPage: true,
    urlSuffix: "uiux15",
    innerPageData: UiUxProjectPageData.uiux15,
  },
  {
    title: "moneyHop Registration Flow Design",
    caption: "UiUx Design",
    image: ImageHelper.HopRegistrationFlowDesign,
    fullWebSS: false,
    hasInnerPage: true,
    urlSuffix: "uiux16",
    innerPageData: UiUxProjectPageData.uiux16,
  },
  {
    title: "Kidzy Habit Tracker  App Ui Concepts",
    caption: "UiUx Design",
    image: ImageHelper.KidzyHabitTrackerAppUiConceptsbyTZ,
    fullWebSS: false,
    hasInnerPage: true,
    urlSuffix: "uiux17",
    innerPageData: UiUxProjectPageData.uiux17,
  },
  {
    title: "SOS Manager App Single Screen",
    caption: "UiUx Design",
    image: ImageHelper.SOSEmergencyAppCover,
    fullWebSS: false,
    hasInnerPage: true,
    urlSuffix: "uiux18",
    innerPageData: UiUxProjectPageData.uiux18,
  },
  {
    title: "MenOnline",
    caption: "UiUx Design",
    image: ImageHelper.MenuOnline,
    fullWebSS: false,
    hasInnerPage: true,
    urlSuffix: "uiux19",
    innerPageData: UiUxProjectPageData.uiux19,
  },
  {
    title: "LevelNext Web",
    caption: "UiUx Design",
    image: ImageHelper.LevelNextWeb,
    fullWebSS: false,
    hasInnerPage: true,
    urlSuffix: "uiux20",
    innerPageData: UiUxProjectPageData.uiux20,
  },
  {
    title: "MenOnline Foundation",
    caption: "UiUx Design",
    image: ImageHelper.MenuOnlineFoundationCover,
    fullWebSS: false,
    hasInnerPage: true,
    urlSuffix: "uiux21",
    innerPageData: UiUxProjectPageData.uiux21,
  },
  {
    title: "Finverse Guru Revamped",
    caption: "UiUx Design",
    image: ImageHelper.FinverseGuruRevampedbyTZ,
    fullWebSS: false,
    hasInnerPage: true,
    urlSuffix: "uiux22",
    innerPageData: UiUxProjectPageData.uiux22,
  },
  {
    title: "FamilyOffice360",
    caption: "UiUx Design",
    image: ImageHelper.FamilyOffice360,
    fullWebSS: false,
    hasInnerPage: true,
    urlSuffix: "uiux23",
    innerPageData: UiUxProjectPageData.uiux23,
  },
  {
    title: "Sundesh Solar Website Revamp",
    caption: "UiUx Design",
    image: ImageHelper.SundeshSolarWebsiteRevamp,
    fullWebSS: false,
    hasInnerPage: true,
    urlSuffix: "uiux24",
    innerPageData: UiUxProjectPageData.uiux24,
  },
  {
    title: "A1 Visas Passport Photo Service Landing Page",
    caption: "UiUx Design",
    image: ImageHelper.A1VIsasPassportServicesLandingPage,
    fullWebSS: false,
    hasInnerPage: true,
    urlSuffix: "uiux25",
    innerPageData: UiUxProjectPageData.uiux25,
  },
  {
    title: "Artyss",
    caption: "UiUx Design",
    image: ImageHelper.Artyss,
    fullWebSS: false,
    hasInnerPage: true,
    urlSuffix: "uiux26",
    innerPageData: UiUxProjectPageData.uiux26,
  },
  {
    title: "GRM Website Design",
    caption: "UiUx Design",
    image: ImageHelper.GRM,
    fullWebSS: false,
    hasInnerPage: true,
    urlSuffix: "uiux27",
    innerPageData: UiUxProjectPageData.uiux27,
  },
  {
    title: "Saish H & F",
    caption: "UiUx Design",
    image: ImageHelper.SaishHF,
    fullWebSS: false,
    hasInnerPage: true,
    urlSuffix: "uiux28",
    innerPageData: UiUxProjectPageData.uiux28,
  },

  {
    title: "UpStreamLife",
    caption: "UiUx Design",
    image: ImageHelper.UpStreamLife,
    fullWebSS: false,
    hasInnerPage: true,
    urlSuffix: "uiux29",
    innerPageData: UiUxProjectPageData.uiux29,
  },
  {
    title: "TeachersIndia UI Revamped",
    caption: "UiUx Design",
    image: ImageHelper.TeachersIndiaUiRevamped,
    fullWebSS: false,
    hasInnerPage: true,
    urlSuffix: "uiux30",
    innerPageData: UiUxProjectPageData.uiux30,
  },
  {
    title: "FMSCI Homepage Revamp",
    caption: "UiUx Design",
    image: ImageHelper.FMSCIHomepageRevamp,
    fullWebSS: false,
    hasInnerPage: true,
    urlSuffix: "uiux31",
    innerPageData: UiUxProjectPageData.uiux31,
  },
  {
    title: "Meco Web UiUx Design",
    caption: "UiUx Design",
    image: ImageHelper.MecoWebUi,
    fullWebSS: false,
    hasInnerPage: true,
    urlSuffix: "uiux32",
    innerPageData: UiUxProjectPageData.uiux32,
  },
  {
    title: "Meco Web UiUx Final Design",
    caption: "UiUx Design",
    image: ImageHelper.MecoFinalWebUi,
    fullWebSS: false,
    hasInnerPage: true,
    urlSuffix: "uiux33",
    innerPageData: UiUxProjectPageData.uiux33,
  },
  {
    title: "EdXplore Admin Panel",
    caption: "UiUx Design",
    image: ImageHelper.EdXploreAdminPanel,
    fullWebSS: false,
    hasInnerPage: true,
    urlSuffix: "uiux34",
    innerPageData: UiUxProjectPageData.uiux34,
  },
  {
    title: "EdXplore Admin Panel [Mobile Version]",
    caption: "UiUx Design",
    image: ImageHelper.EdXploreAdminPanelMobileVersion,
    fullWebSS: false,
    hasInnerPage: true,
    urlSuffix: "uiux35",
    innerPageData: UiUxProjectPageData.uiux35,
  },
  {
    title: "EdXplore Header Revamp",
    caption: "UiUx Design",
    image: ImageHelper.EdXploreHeaderRevamp,
    fullWebSS: false,
    hasInnerPage: true,
    urlSuffix: "uiux36",
    innerPageData: UiUxProjectPageData.uiux36,
  },
  {
    title: "SkillMine [UK]",
    caption: "UiUx Design",
    image: ImageHelper.SkillMineUk,
    fullWebSS: false,
    hasInnerPage: true,
    urlSuffix: "uiux37",
    innerPageData: UiUxProjectPageData.uiux37,
  },
  {
    title: "SkillMine [India]",
    caption: "UiUx Design",
    image: ImageHelper.SkillMineIndia,
    fullWebSS: false,
    hasInnerPage: true,
    urlSuffix: "uiux38",
    innerPageData: UiUxProjectPageData.uiux38,
  },
  {
    title: "SkillMine [KSA]",
    caption: "UiUx Design",
    image: ImageHelper.SkillMineKsa,
    fullWebSS: false,
    hasInnerPage: true,
    urlSuffix: "uiux39",
    innerPageData: UiUxProjectPageData.uiux39,
  },
  {
    title: "SkillMine Auth",
    caption: "UiUx Design",
    image: ImageHelper.SkillMineAuth,
    fullWebSS: false,
    hasInnerPage: true,
    urlSuffix: "uiux40",
    innerPageData: UiUxProjectPageData.uiux40,
  },
  {
    title: "Hotel & Resort Management",
    caption: "UiUx Design",
    image: ImageHelper.HotelResortManagement,
    fullWebSS: false,
    hasInnerPage: true,
    urlSuffix: "uiux41",
    innerPageData: UiUxProjectPageData.uiux41,
  },
  // {
  //   title: "ZeroZilla Profile",
  //   caption: "UiUx Design",
  //   image: ImageHelper.ZeroZillaProfile,
  //   fullWebSS: false,
  //   hasInnerPage: true,
  //   urlSuffix: "uiux42",
  //   innerPageData: UiUxProjectPageData.uiux42,
  // },
];

export default PortfolioDataUiUx;
