import { SectionTitleHelper } from "../../Helpers/SectionTitleHelper";

export const SkillSectionData = {
  sectionTitle: SectionTitleHelper.skills,
  skillsCategories: {
    categoryTitle1: "Skillset",
    skills: [
      {
        id: 1,
        skill: "Web Designing",
        percetage: 90,
      },
      {
        id: 2,
        skill: "UI/UX Designing",
        percetage: 95,
      },
      {
        id: 3,
        skill: "Web Developement",
        percetage: 80,
      },
      {
        id: 4,
        skill: "Graphic Designing",
        percetage: 85,
      },
      {
        id: 5,
        skill: "Web Deployment & Hosting",
        percetage: 75,
      },
    ],
    categoryTitle2: "Technology",
    tech: [
      {
        id: 1,
        skill: "React Js, Redux, Node Js",
        percetage: 85,
      },
      {
        id: 2,
        skill: "JavaScript, TypeScript & Jquery",
        percetage: 80,
      },
      {
        id: 3,
        skill: "HTML5 & CSS3",
        percetage: 95,
      },
      {
        id: 4,
        skill: "Bootstrap, Material UI, Sass & Less",
        percetage: 85,
      },
      {
        id: 5,
        skill: "Graph QL, Axios, REST API",
        percetage: 80,
      },
    ],
  },
};
