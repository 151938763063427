export const SectionTitleHelper = {
  headerSectionTitle: "Tabrez Dal",
  aboutMe: "About Me",
  skills: "Skills",
  tools: "Tools",
  technologies: "Technologies",
  projects: "Projects",
  footerSectionTitle: "Connect with me",
  backButton: "Back to homepage",
};
