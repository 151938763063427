import ImageHelper from "../../Helpers/ImageHelper";

export const TechnologiesSectionData = {
  FeatureTech: [
    {
      id: 1,
      techIcon: ImageHelper.ReactIcon,
      techName: "React",
    },
    {
      id: 2,
      techIcon: ImageHelper.ReduxIcon,
      techName: "Redux",
    },
    {
      id: 3,
      techIcon: ImageHelper.GraphQLIcon,
      techName: "Graph QL",
    },
    {
      id: 4,
      techIcon: ImageHelper.NodeJsIcon,
      techName: "Node Js",
    },
    {
      id: 5,
      techIcon: ImageHelper.GoogleBardIcon,
      techName: "Google Bard",
    },
    {
      id: 6,
      techIcon: ImageHelper.ChatGPTIcon,
      techName: "ChatGPT",
    },
  ],
  NormalTech: [
    {
      id: 1,
      techIcon: ImageHelper.HtmlIcon,
      techName: "HTML5",
    },
    {
      id: 2,
      techIcon: ImageHelper.CssIcon,
      techName: "CSS3",
    },
    {
      id: 3,
      techIcon: ImageHelper.SaasIcon,
      techName: "Sass",
    },
    {
      id: 4,
      techIcon: ImageHelper.LessIcon,
      techName: "Less",
    },
    {
      id: 5,
      techIcon: ImageHelper.MuiIcon,
      techName: "Material UI",
    },
    {
      id: 6,
      techIcon: ImageHelper.BootstrapIcon,
      techName: "Bootstrap",
    },
    {
      id: 7,
      techIcon: ImageHelper.MySqlIcon,
      techName: "MySQL",
    },
    {
      id: 8,
      techIcon: ImageHelper.JsonIcon,
      techName: "JSON",
    },
    {
      id: 9,
      techIcon: ImageHelper.JavaScriptIcon,
      techName: "JavaScript",
    },
    {
      id: 10,
      techIcon: ImageHelper.TypescriptIcon,
      techName: "Typescript",
    },
    {
      id: 11,
      techIcon: ImageHelper.AxiosIcon,
      techName: "Axio",
    },
    {
      id: 12,
      techIcon: ImageHelper.JqueryIcon,
      techName: "Jquery",
    },
  ],
};
