import ImageHelper from "../../Helpers/ImageHelper";

export const HeroSectionData = {
  introStatingLine: "This is me",
  name: "Tabrez Dal",
  introLine:
    "a Software Developer and Product Designer with over 4 years of experience, holding a Bachelor's Degree in Computer Science and Engineering.",
  buttonData: {
    name: "Download CV",
    url: "https://drive.google.com/file/d/1GN7y4_uQgm7XaMGmB_U52roY3tSuByPt/view?usp=sharing",
    url2: "https://drive.google.com/file/d/1McjuJeJgD-oTfHLbVzt9VJztWN_cR3Vr/view?usp=sharing",
  },
  heroImage: ImageHelper.HeroImage,
};
