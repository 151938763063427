import ImageHelper from "../../Helpers/ImageHelper";

const PortfolioDataPoster = [
  {
    title: "CricBuzz",
    caption: "Banner",
    image: ImageHelper.CricBuzzBanner,
  },
  {
    title: "FDVsRealEstate",
    caption: "Youtube Thumbnail",
    image: ImageHelper.FDVsRealEstate,
  },
  {
    title: "MindBrowser",
    caption: "Infographic",
    image: ImageHelper.MindBrowserInfographic,
  },
  {
    title: "FreshExpress Recipe Book",
    caption: "Book Cover",
    image: ImageHelper.FreshExpressRecipeBook1,
  },
  {
    title: "FreshExpress Catalog",
    caption: "Catalog",
    image: ImageHelper.FreshExpressCatalog,
  },
  {
    title: "FreshExpress Recipe Book",
    caption: "Book Cover",
    image: ImageHelper.FreshExpressRecipeBook2,
  },
  {
    title: "IGhostia YT Streamer",
    caption: "Poster",
    image: ImageHelper.IGhostiaPoster,
  },

  {
    title: "Naaz Classes",
    caption: "Poster",
    image: ImageHelper.NaazClassesPoster,
  },
  {
    title: "Lan Mania Game",
    caption: "Poster",
    image: ImageHelper.NobleLanManiaPoster,
  },
  // {
  //   title: "Linkedin",
  //   caption: "Cover",
  //   image: ImageHelper.LinkedinCover,
  // },
  // {
  //   title: "Topclues Solutions",
  //   caption: "Banner",
  //   image: ImageHelper.TopcluesInfographic,
  // },
  // {
  //   title: "Topclues Solutions",
  //   caption: "Infographic Banner",
  //   image: ImageHelper.TopcluesCTRPost,
  // },
];

export default PortfolioDataPoster;
