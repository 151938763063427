const TestimonialData = [
  {
    avatar:
      "https://media.licdn.com/dms/image/D4D03AQFtj0TSNT3j8Q/profile-displayphoto-shrink_100_100/0/1688669159050?e=1720656000&v=beta&t=_pNKo620KSjCse25Sv2NrItEJLCkMPpkRxeSPaQ72Lw",
    name: "Shahzaib Arshad",
    review:
      "What sets Tabrez apart is his remarkable creativity and attention to detail. He consistently approached each design challenge with a fresh perspective, offering innovative solutions that elevated the overall user experience. His designs not only met the functional requirements of the project but also added a layer of aesthetic refinement that resonated with our target audience. Tabrez's collaboration skills were equally notable. He seamlessly integrated feedback and iterated on his designs, always prioritizing the project's goals while remaining open to suggestions. His professionalism and effective communication made him a pleasure to work with, fostering a positive and productive environment within our team. I'd highly recommend Tabrez for any UI/UX project",
    sourceUrl:
      "https://www.linkedin.com/in/tabrezdal/details/recommendations/?detailScreenTabIndex=0",
  },
  {
    avatar:
      "https://media.licdn.com/dms/image/D4E03AQEwp6ei8NSelw/profile-displayphoto-shrink_100_100/0/1707323308566?e=1720656000&v=beta&t=1g-9bxkpwt_Z7ztai5LDdHY1eylVwXwPyIBzMyn-G2o",
    name: "Malvika Singh",
    review:
      "Working with Tabrez Dal on my Apparel Catalog Design and Ui/Ux project was an absolute delight. Tabrez's keen eye for detail and creativity shone through in every aspect of the project. From the intricate flat sketches and technical sketches for the catalog to his thorough exploration of user journeys, personas, and the entire Ui/Ux process, Tabrez showcased a level of dedication and skill that sets him apart. The end results were not just designs; they were a perfect representation of my vision brought to life. Highly recommended!",
    sourceUrl:
      "https://www.linkedin.com/in/tabrezdal/details/recommendations/?detailScreenTabIndex=0",
  },
  {
    avatar:
      "https://media.licdn.com/dms/image/C5103AQFP2uiAMnCEog/profile-displayphoto-shrink_100_100/0/1580806984740?e=1720656000&v=beta&t=5veRrSPV6RD6cE1LgSS4eRBzwGcNSuwzM4qlTkFteGw",
    name: "Viral Patel",
    review:
      "Working with Tabrez Dal has been an exceptional experience. His creativity, professionalism, and attention to detail truly set him apart. I was thoroughly impressed with the results he delivered on our project. The complimentary banner edits were a generous gesture for being Loyal Customer, and the two additional variants he provided showcased his commitment to excellence. He exceeded our expectations, and I wholeheartedly recommend him to anyone in need of top-tier design services. Looking forward to future collaborations!",
    sourceUrl:
      "https://www.linkedin.com/in/tabrezdal/details/recommendations/?detailScreenTabIndex=0",
  },
  {
    avatar:
      "https://media.licdn.com/dms/image/D5603AQHWZMUayQUfrA/profile-displayphoto-shrink_100_100/0/1675251692358?e=1720656000&v=beta&t=JJhW8x5exaapc6Q390jFl4if5WumWxWWPPBViI0F6A4",
    name: "Mohammed Omer Farooq",
    review:
      "I find Tabrez has one unique sense of creativity in his work and I feel he is the more creative person which makes him better for Web Developer and Graphic Design professional. Communication is polite very humble person person to talk with.",
    sourceUrl:
      "https://www.linkedin.com/in/tabrezdal/details/recommendations/?detailScreenTabIndex=0",
  },
  {
    avatar:
      "https://media.licdn.com/dms/image/D4D03AQElgtpz9r0SAg/profile-displayphoto-shrink_100_100/0/1673880834197?e=1720656000&v=beta&t=IaTrYfbMeeWTWs3FpQd_ysLJiQHsj8GIebKRDghHhHw",
    name: "Lee-Anthony Pinn",
    review:
      "This is the first time I have reached out to someone to work on a project for me and Tabrez really has exceeded all expectations. Quality work and easy to work with.",
    sourceUrl:
      "https://www.linkedin.com/in/tabrezdal/details/recommendations/?detailScreenTabIndex=0",
  },
  {
    avatar:
      "https://media.licdn.com/dms/image/C4E03AQEH_rP45WNdKQ/profile-displayphoto-shrink_100_100/0/1592461133539?e=1720656000&v=beta&t=u36uFCC52gMhUGfc-fKWxODj8VeC-K_WoYZSOZVtVqM",
    name: "Kishan Majeethiya",
    review:
      "Tabrez Dal's Design sense exceeded all my expectations. The designs he delivered for my brands were not only visually stunning but also resonated perfectly with my brand identity. What sets Tabrez apart is not just his design acumen but also his commitment to client satisfaction. I was not only impressed enough to become a returning client but also confidently referred him to friends seeking a professional of his caliber. Tabrez's work speaks for itself, and I couldn't be happier with the results.",
    sourceUrl:
      "https://www.linkedin.com/in/tabrezdal/details/recommendations/?detailScreenTabIndex=0",
  },
  {
    avatar:
      "https://media.licdn.com/dms/image/D4E03AQEFwVYLby9YvA/profile-displayphoto-shrink_200_200/0/1685436020851?e=2147483647&v=beta&t=6-Rkq-j_zGUUwT56PbRoOCJ-tRFfWTEykNR2ZVWgxm4",
    name: "Zak Furness",
    review: "I have to be honest when I leave reviews even if the freelancer does a good job. He is a good designer but if he promises to drop the attitude he is a great designer.",
    sourceUrl:
      "https://www.linkedin.com/in/tabrezdal/details/recommendations/?detailScreenTabIndex=0",
  },
];

export default TestimonialData;
