export const FooterSocialMediaLinksData = [
  {
    id: 1,
    url: "https://www.facebook.com/tabrezdal",
    iconClass: "fa fa-facebook fa-2x fa-fw",
  },
  {
    id: 2,
    url: "https://www.instagram.com/tabrezdal",
    iconClass: "fa fa-instagram fa-2x fa-fw",
  },
  {
    id: 3,
    url: "https://twitter.com/tabrezdal",
    iconClass: "fa fa-twitter fa-2x fa-fw",
  },
  {
    id: 4,
    url: "https://dribbble.com/tabrezdal",
    iconClass: "fa fa-dribbble fa-2x fa-fw",
  },
  {
    id: 5,
    url: "https://www.behance.net/tabrezdal",
    iconClass: "fa fa-behance fa-2x fa-fw",
  },
  {
    id: 6,
    url: "https://www.pinterest.com/tabrezdal",
    iconClass: "fa fa-pinterest fa-2x fa-fw",
  },
  {
    id: 7,
    url: "https://github.com/tabrezdal",
    iconClass: "fa fa-github fa-2x fa-fw",
  },
  {
    id: 8,
    url: "https://www.linkedin.com/in/tabrezdal",
    iconClass: "fa fa-linkedin fa-2x fa-fw",
  },
];
